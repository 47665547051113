import React, { Component } from "react";
import { Link } from "react-router-dom";
import { computed, observable, action } from "mobx";
import MainBody from "./Components/Body";
import { Helmet } from "react-helmet";
import Modal from "./Components/Modal";

import styled, { keyframes } from "styled-components";

import { observer, inject } from "mobx-react";
@inject("store")
@observer
class Body extends Component {
  state = {
    showModal: false,
    hasModalBeenShown: false, // Initialisieren Sie den Zustand hier
  };

  static getDerivedStateFromProps(props, state) {
    if (props.store.profile.credits <= 0 && !state.hasModalBeenShown) {
      return { showModal: true };
    }
    return null;
  }

  closeAndMarkModalAsShown = () => {
    this.setState({
      showModal: false,
      hasModalBeenShown: true, // Zustand aktualisieren, um das erneute Anzeigen des Modals zu verhindern
    });
  };

  @computed get permissions() {
    return this.props.store.tools.filter((tool) =>
      tool.permissions.some((r) =>
        this.props.store.profile.permissions.includes(r)
      )
    );
  }

  @computed get job() {
    return this.permissions.filter((tool) => tool.category === "Job");
  }

  @computed get internship() {
    return this.permissions.filter((tool) => tool.category === "Internship");
  }

  @computed get university() {
    return this.permissions.filter((tool) => tool.category === "University");
  }

  @computed get others() {
    return this.permissions.filter((tool) => tool.category === "Tools");
  }

  // @computed get personal() {
  //   return this.permissions.filter((tool) => tool.category === "Personal");
  // }

  // @computed get social() {
  //   return this.permissions.filter((tool) => tool.category === "Social");
  // }

  // @computed get programming() {
  //   return this.permissions.filter((tool) => tool.category === "Programming");
  // }

  render() {
    return (
      <>
        <Helmet>
          <title>{`Tools - AnschreibenAI`}</title>
        </Helmet>

        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
          {this.job.length ? (
            <>
              <Title title="Arbeitsplatz" />
              <Grid>
                {this.job.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.internship.length ? (
            <>
              <Title title="Praktikum / Ausbildung" />
              <Grid>
                {this.internship.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.university.length ? (
            <>
              <Title title="Studium" />
              <Grid>
                {this.university.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.others.length ? (
            <>
              <Title title="Tools" />
              <Grid>
                {this.others.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {/* {this.personal.length ? (
            <>
              <Title title="Personal" />
              <Grid>
                {this.personal.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}

          {/* {this.social.length ? (
            <>
              <Title title="Online" />
              <Grid>
                {this.social.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}
        </MainBody>

        {this.state.showModal && (
          <StyledModal className={this.state.showModal ? "open" : ""}>
            <Modal
              isOpen={this.state.showModal}
              onClose={this.closeAndMarkModalAsShown}
              title={"Willkommen! 🙂👋 Schön, dass du da bist! 🎉💖"}
              message="Entdecke unsere Tools! 😊 Du brauchst Credits, um sie voll zu nutzen. Ist dein Guthaben alle? Lade es auf und genieße unseren Service ohne Unterbrechung."
            />
          </StyledModal>
        )}
      </>
    );
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledModal = styled.div`
  /* Andere Modal-Stile */

  &.open {
    animation: ${fadeIn} 0.5s forwards;
  }
`;

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
    {" "}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
    {title}
  </h2>
);

export const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => (
  <Link to={to || "/"} className="flex relative ">
    <div
      className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}
    >
      <div className="p-4">
        <div
          className={`uppercase tracking-wide text-sm text-${
            fromColor ? fromColor : "green-500"
          } font-semibold leading-none`}
        >
          {group || "New"}
        </div>
        <div
          href="#"
          className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
        >
          {title}
        </div>
        <p className="mt-1 pr-1 text-sm ">{desc} </p>
      </div>
    </div>
  </Link>
);

export default Body;
