import React, { Component } from "react";
import {
  PencilIcon,
  ClockIcon,
  InformationCircleIcon,
  DuplicateIcon,
} from "@heroicons/react/outline";

import { Helmet } from "react-helmet";

import Header from "../Components/Header";
import Body, { Grid, Col } from "../Components/Body";
import Button from "../Components/Button";
import ButtonFeedback from "../Components/ButtonFeedback";
import Output from "../Components/Output";
import Countdown from "react-countdown";
import { withRouter } from "react-router-dom";

import { observable, makeObservable, computed } from "mobx";
import { observer, inject } from "mobx-react";

import { TrashIcon } from "@heroicons/react/outline";
import EntryText from "../Components/EntryText";

import EntryTabs from "../Components/EntryTabs";
import EntryPrompt from "../Components/EntryPrompt";
import EntryInput from "../Components/EntryInput";
import EntryN from "../Components/EntryN";
import Modal from "../Components/Modal";

import Filter from "bad-words";
import styled, { keyframes } from "styled-components";

import "./backToTop.css"; // create and import a CSS file for your back-to-top button

import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";
import PDFWorker from "pdfjs-dist/legacy/build/pdf.worker.mjs";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";

// Log to check if pdfjsLib is imported correctly
import LoadingIcon from "./Spin_Loading_Color.svg";

let filterBadWords = new Filter();

@inject("store")
@observer
class Tool extends Component {
  countdownFeedback;
  @observable tool = {};
  @observable uploadProgress = 0;

  @observable.deep prompts = [];
  @observable currentPrompt = 0;
  @observable currentOption = "Jetzt loslegen";

  @observable isUploading = false; // Zustandsvariable für das Hochladen

  @observable error = "";
  @observable feedbackSubmitted = false;

  @observable output = "";
  @observable outputs = [];
  @observable code = "";

  @observable loading = false;

  @observable buttonText = "Anschreiben generieren";

  @observable showBackToTop = false;

  @observable date = Date.now() + 1000;
  countdown = [];

  // @observable showBackToTop = false;

  // Add a new observable for the uploaded CV
  @observable cvFile = null;

  // Computed property to check if user has a "pro" plan
  @computed get isUserPremium() {
    //console.log(this.props.store.profile.plan);
    return (
      this.props.store.profile.plan === "trial" ||
      this.props.store.profile.plan === "premium"
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.store.showPremiumFeatureModal &&
      !prevState.showPremiumFeatureModal
    ) {
      this.setState({ showProPlanWarningModal: true });
    }
  }

  // computed properties: derived form the state
  @computed get isGenerateButtonDisabled() {
    if (this.loading) {
      return true;
    }

    return false;
  }

  @computed get disabled() {
    if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
      return true;
    }

    // this.prompts[this.currentPrompt].prompts[promptIndex].value
    return false;
  }

  @computed get isMinLength() {
    if (!this.props.prompt.min) {
      return false;
    }
    if (!this.props.prompt.type === "number") {
      return false;
    }

    return false;
  }

  handlePremiumFeature = () => {
    if (!this.isUserPremium) {
      this.setState({ showProPlanWarningModal: true });
    } else {
      // Logic for premium users
    }
  };

  handleProPromptClick = () => {
    if (!this.isUserPremium) {
      // console.log("this.isUserPremium ", this.isUserPremium);
      this.setState({ showProPlanWarningModal: true });
    } else {
      // Logic for Pro users (if any)
    }
  };

  renderProPlanWarningModal() {
    return (
      <StyledModal className={this.state.showProPlanWarningModal ? "open" : ""}>
        <Modal
          isOpen={this.state.showProPlanWarningModal}
          onClose={() => this.setState({ showProPlanWarningModal: false })}
          title="✨Premium Feature"
          message="Hey, cool, dass du Interesse an dieser Funktion hast! Sie gehört zu unseren Premium Features. Wie wäre es mit einem Upgrade, um sie in vollem Umfang zu nutzen? Unser Bewerbungsschreiben Expert Tool ist mit geballtem Expertenwissen ausgestattet. Es passt dein Anschreiben perfekt an deinen Lebenslauf an, was deine Chancen auf eine Einladung zum Vorstellungsgespräch deutlich erhöht. Klingt das nicht super? Probier's aus und bring deine Bewerbung auf das nächste Level!"
          // Add additional buttons or text if needed
        />
      </StyledModal>
    );
  }

  constructor(props) {
    super(props);
    makeObservable(this);

    // get based on the current url path, and store in inside component state
    this.tool = this.props.store.getToolByUrl(this.props.location.pathname);
    if (!this.tool) {
      window.location.href = "/";
    } else {
      this.prompts = [...this.tool.prompts];
    }

    this.state = {
      showBackToTop: false,
      showModal: false,
      showProPlanWarningModal: false, // New state variable for Pro Plan Warning Modal
      fileError: "", // Add this line
      dataImported: false, // New state variable
    };

    this.attachScrollListener();
  }

  // Handler for CV file change
  handleCVUpload = (event) => {
    if (!this.isUserPremium) {
      this.setState({ showCVUploadPremiumWarningModal: true });
      return;
    }

    this.setState({ fileError: "" });
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      this.isUploading = true; // Hochladen beginnt
      this.cvFile = file;
      this.extractTextFromPDF(file);
    } else {
      // Handle non-PDF files or reset if no file is selected
      this.isUploading = false; // Hochladen beendet
      this.cvFile = null;
    }
  };

  // Add a method to extract text from PDF
  extractTextFromPDF = (file) => {
    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      try {
        const typedArray = new Uint8Array(event.target.result);
        const loadingTask = pdfjsLib.getDocument({ data: typedArray });

        const pdf = await loadingTask.promise;

        // Check if PDF has more than 4 pages
        if (pdf.numPages > 4) {
          console.error("Error: PDF is longer than 4 pages.");
          this.handleErrorPDF("PDF should not be longer than 4 pages."); // Handle error appropriately
          return; // Exit the function
        }

        let extractedText = "";

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const textItems = textContent.items.map((item) => item.str).join(" ");
          extractedText += textItems + " ";
        }

        // Now send this extractedText to your backend
        this.sendPDFTextToBackend(extractedText);
      } catch (error) {
        console.error("Error extracting text from PDF", error);
        this.handleErrorPDF(error.message); // Handle the error appropriately
        this.setState({ fileError: "Error processing the file." });
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  // You can create a separate method for handling errors
  handleErrorPDF = (errorMessage) => {
    // Update your state to reflect the error
    // For example:
    this.setState({
      fileError: errorMessage,
    });
    //this.error = errorMessage;
    this.loading = false;
    this.isUploading = false;
  };
  // Add a method to send the extracted text to the backend
  sendPDFTextToBackend = async (extractedText) => {
    try {
      this.loading = true; // Start loading
      //console.log(extractedText);

      let progress = 0;
      const interval = setInterval(() => {
        progress += 1;
        this.uploadProgress = progress;

        if (progress >= 99) {
          clearInterval(interval); // Stop at 50%
        }
      }, 100); // Adjust time interval for slower progress

      // Replace with your API endpoint and appropriate payload
      const response = await this.props.store.api.post(this.tool.apiCv, {
        pdfText: extractedText,
        currentSchema: this.tool.currentSchema,
      });

      // await this.props.store.api.post(this.tool.apiCv, postObj);

      // Handle the response as needed
      //console.log("Backend response", response);

      // Check if the response is successful
      if (response && response.data && response.data.success) {
        // Log the data received from the backend
        //console.log("Processed CV Data:", response.data.data);

        this.updatePromptsWithData(response.data.data);
      } else {
        //console.log("Processing error or no data received");
      }
    } catch (error) {
      //console.error("Error sending PDF text to backend", error);
    } finally {
      this.uploadProgress = 100; // Finish with 100%
      this.loading = false; // Stop loading regardless of the outcome
      this.isUploading = false; // Hochladen beendet
      this.setState({ dataImported: true });
      // Hide the message after 3 seconds
      setTimeout(() => {
        this.setState({ dataImported: false });
      }, 3000);
    }
  };

  updatePromptsWithData = (extractedData) => {
    this.prompts.forEach((prompt) => {
      prompt.prompts.forEach((promptInput) => {
        const matchingData = extractedData.find(
          (data) => data.attr === promptInput.attr
        );
        if (matchingData) {
          promptInput.value = matchingData.value;
        }
      });
    });
  };

  attachScrollListener() {
    window.addEventListener("scroll", throttle(this.handleScroll, 500));
  }

  handleScroll = () => {
    const currentScrollPos = window.scrollY;
    // console.log(currentScrollPos);
    const isVisible = currentScrollPos > 100;
    this.showBackToTop = isVisible;
    // console.log(this.showBackToTop);
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.setState({ showBackToTop: false });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleCurrentPrompt = (val) => {
    if (
      this.prompts[val].title === "Bewerbungsschreiben Expert" &&
      !this.isUserPremium
    ) {
      this.setState({ showProPlanWarningModal: true });
      this.switchToBasicTool(); // Switch to basic tool
    } else {
      this.currentPrompt = val;
    }
  };

  switchToBasicTool = () => {
    const basicToolIndex = this.prompts.findIndex(
      (prompt) => prompt.title === "Bewerbungsschreiben Basic"
    );
    if (basicToolIndex !== -1) {
      this.currentPrompt = basicToolIndex;
    }
  };

  checkMinimumPrompts = () => {
    let shouldReturn = false;

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (prompt.min) {
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} muss die Mindestanzahl von ${prompt.min} Zeichen erfüllen`;
        }
      }
    });

    return shouldReturn;
  };

  clearExampleTimeout = [];

  onStartUsing = async () => {
    this.loading = false;
    this.error = "";
    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });
    this.currentOption = "Jetzt loslegen";
  };

  onExample = async () => {
    // Reset state and close modal
    this.setState({ showModal: false });
    this.clearExampleState();

    // Prepare examples from prompts
    this.prepareExamples();

    // Set up the output, code, and outputs if available in examples
    this.setupExampleOutput();
  };

  clearExampleState = () => {
    this.loading = true;
    this.error = "";
    this.output = "";
    this.outputs = [];
    this.code = ``;
    this.currentOption = "Example";

    // Clear timeouts
    this.clearExampleTimeout.forEach(clearTimeout);
    this.clearExampleTimeout = [];
  };

  prepareExamples = () => {
    const currentPrompts = this.prompts[this.currentPrompt].prompts;
    currentPrompts.forEach((prompt, promptIndex) => {
      //console.log("Example content:", prompt.example); // Debugging line
      if (prompt.example) {
        prompt.example.split("").forEach((char, index) => {
          this.setPromptValueAfterDelay(promptIndex, char, index);
        });
      } else {
        //console.error("prompt.example is undefined", prompt);
      }
    });
  };

  setPromptValueAfterDelay = (promptIndex, char, index) => {
    const delay = 7 * (index + 1);
    this.clearExampleTimeout.push(
      setTimeout(() => {
        this.prompts[this.currentPrompt].prompts[promptIndex].value += char;
      }, delay)
    );
  };

  setupExampleOutput = () => {
    const { output, code, outputs } = this.prompts[this.currentPrompt].example;

    if (output) {
      this.setExampleOutputAfterDelay(output);
    }
    if (code) {
      this.setExampleCodeAfterDelay(code);
    }
    if (outputs) {
      this.setExampleOutputsAfterDelay(outputs);
    }
  };

  setExampleOutputAfterDelay = (output) => {
    const delay = output.length * 7 + 500;
    this.clearExampleTimeout.push(
      setTimeout(() => {
        this.output = output;
        this.finalizeExample();
      }, delay)
    );
  };

  setExampleCodeAfterDelay = (code) => {
    const delay = code.length * 7 + 500;
    this.clearExampleTimeout.push(
      setTimeout(() => {
        this.code = code;
        this.loading = false;
      }, delay)
    );
  };

  setExampleOutputsAfterDelay = (outputs) => {
    const delay = outputs.length * 7 + 500;
    this.clearExampleTimeout.push(
      setTimeout(() => {
        this.outputs = outputs;
        this.finalizeExample();
      }, delay)
    );
  };

  finalizeExample = () => {
    this.loading = false;
    this.currentOption = "Jetzt loslegen";
    if (this.prompts[this.currentPrompt].prompts.length > 0) {
      this.prompts[this.currentPrompt].prompts[0].value += " ";
    }
  };

  // check if the content is unsave of the prompt
  sanitizeAllPrompts = () => {
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (!prompt.value) {
        return false;
      }
      if (prompt.type === "number") {
        return false;
      }

      prompt.value = prompt.value.trim();

      if (filterBadWords.isProfane(prompt.value)) {
        prompt.error = "Unsafe content , please try different language";
        throw Error("Unsafe content");
      }
    });
  };

  contentFilterFlagged = async (response) => {
    this.error = response.message;

    this.date = Date.now() + 5000;
    this.countdown.forEach((countdown) => {
      if (countdown) {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
  };

  checkOutput = (output) => {
    if (output) {
      output = output.replace(/^\s+|\s+$/g, "");
      // output = output.replace(/\s{2,}/g, ' ')
    }
    return output;
  };

  @computed get language() {
    let language = "";
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (prompt.attr === "language") {
        language = `${prompt.value}`;
      }
    });
    return language;
  }

  onGenerateClick = async () => {
    //resetFeedback State
    this.feedbackSubmitted = false;

    this.resetGenerateState();

    this.loading = true;

    if (this.checkMinimumPrompts()) {
      this.loading = false;
      this.buttonText = "Anschreiben generieren";
      return;
    }

    const postObj = this.createPostObject();

    try {
      const response = await this.props.store.api.post(this.tool.api, postObj);
      this.processResponse(response);

      this.scrollToTop();
    } catch (error) {
      this.handleError(error);
    }
  };

  resetGenerateState = () => {
    this.error = "";
    this.output = "";
    this.code = ``;
    this.outputs = [];
    this.loading = true;
    this.buttonText = "Bitte warten... Anschreiben wird verfasst";
  };

  createPostObject = () => {
    let postObj = {};
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      // Überprüfe, ob das aktuelle Feld vom Typ 'date-range' ist
      if (prompt.type === "date-range") {
        // Speichere beide Datumswerte zusammen in einem Objekt oder einer Zeichenkette
        postObj[prompt.attr] = {
          startDate: this.props.store.startDate,
          endDate: this.props.store.endDate,
        };
      } else {
        // Für alle anderen Typen den Wert direkt übernehmen
        postObj[prompt.attr] = prompt.value;
      }
    });

    postObj.currentPrompt = this.prompts[this.currentPrompt].title;
    if (this.prompts[this.currentPrompt].n) {
      postObj.n = this.prompts[this.currentPrompt].n;
    }

    return postObj;
  };

  processResponse = (response) => {
    if (!response.data.success) {
      return this.handleUnsuccessfulResponse(response);
    }

    this.handleSuccessfulResponse(response.data);
  };

  handleUnsuccessfulResponse = (response) => {
    if (response.data.error === "No Credits") {
      this.error = response.data.message;
      this.setState({ showModal: true });
    } else if (response.statusCode === 428 || response.status === 428) {
      this.error =
        "Opps.. unsere Server sind überlastet. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
    } else {
      this.contentFilterFlagged(response.data);
      this.buttonText = "Anschreiben generieren";
    }

    this.loading = false;
  };

  renderCVUploadPremiumWarningModal() {
    return (
      <StyledModal
        className={this.state.showCVUploadPremiumWarningModal ? "open" : ""}
      >
        <Modal
          isOpen={this.state.showCVUploadPremiumWarningModal}
          onClose={() =>
            this.setState({ showCVUploadPremiumWarningModal: false })
          }
          title="✨ Premium Feature"
          message="Die Funktion zum Hochladen von Lebensläufen ist ein Premium-Feature. Bitte erwäge ein Upgrade, um diese Funktion nutzen zu können."
        />
      </StyledModal>
    );
  }

  handleSuccessfulResponse = (data) => {
    if (data.output) {
      this.output = this.checkOutput(data.output);
    }

    if (data.code) {
      this.code = data.code;
    }

    if (data.outputs) {
      this.outputs = data.outputs;
    }

    this.date = Date.now() + 10000;
    this.countdown.forEach((countdown) => {
      if (countdown && typeof countdown.start === "function") {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
    this.buttonText = "Anschreiben generieren";
  };

  handleError = (error) => {
    if (error.response && error.response.status === 429) {
      this.error =
        "Opps... unsere Server sind überlastet. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
    }
    this.countdown.forEach((countdown) => {
      if (countdown && typeof countdown.start === "function") {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
    this.buttonText = "Anschreiben generieren";
  };

  renderUploadSpinner() {
    if (this.isUploading) {
      return (
        <StyledModal className="upload-spinner-overlay">
          <ProgressBarContainer>
            <ProgressBar width={this.uploadProgress} />
          </ProgressBarContainer>
          <p>Lebenslauf wird geladen... {this.uploadProgress}%</p>
        </StyledModal>
      );
    }
    return null;
  }

  handleRemoveFile = () => {
    this.setState({ cvFile: null, isUploading: false });
    // Reset the value of the file input
    document.getElementById("cvUpload").value = "";
  };

  //Feedback Feature

  onClickFeedback = async () => {
    // this.loading = true;
    if (this.props.store.starRating === 0) {
      this.props.store.setRatingError(true);
      return;
    }
    await this.handleFeedback();
    // this.date = Date.now() + 10000;
    this.props.store.feedback = "";
    //this.countdownFeedback.start();
    // this.loading = false;
  };
  onChange = (e) => {
    this.props.store.feedback = e.target.value;
  };

  handleFeedback = async () => {
    const postObj = this.createPostObject();

    try {
      await this.props.store.api.post("/user/feedback", {
        feedback: this.props.store.feedback,
        starRating: this.props.store.starRating,
        inputs: postObj, // Eingabefelder
        output: this.output, // Ausgabetext
      });

      //this.refreshFeedback();
      this.feedbackSubmitted = true;

      // setTimeout(() => {
      //   this.feedbackSubmitted = false;
      // }, 3000);

      this.props.store.feedback = "";
      this.props.store.starRating = 0;
    } catch (err) {
      console.log(err);
      this.props.store.feedback = "Fehler beim Senden vom Feedback!";
    }
  };

  render() {
    // required for mobx to pick up deeply nested value
    const currentValue = this.prompts[this.currentPrompt].prompts[0].value;

    return (
      <>
        {this.showBackToTop && (
          <button className="back-to-top" onClick={this.scrollToTop}>
            ↑
          </button>
        )}

        <Helmet>
          <title>{`${this.tool.title} Tool - AnschreibenAI`}</title>
        </Helmet>
        <Header
          title={this.tool.title}
          desc={this.tool.desc}
          Icon={this.tool.Icon}
          fromColor={this.tool.fromColor}
          category={this.tool.category}
          options={[
            {
              title: "Jetzt loslegen",
              Icon: PencilIcon,
              color: this.props.store.profile.credits ? "green" : "red",
              onClick: this.onStartUsing,
            },
            {
              title: "Beispiel",
              color: "yellow",
              Icon: InformationCircleIcon,
              onClick: this.onExample,
            },
          ]}
          currentOption={this.currentOption}
        />
        <Body>
          <Grid>
            <Col span="6">
              {/* CV Upload Field */}

              <div className="mb-4">
                <label
                  htmlFor="cvUpload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lebenslauf hochladen (optional, nur PDF Format)
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledFileInput
                    id="cvUpload"
                    name="cvUpload"
                    type="file"
                    accept="application/pdf"
                    onChange={this.handleCVUpload}
                  />

                  <RemoveButton
                    onClick={this.cvFile ? this.handleRemoveFile : null}
                    fileUploaded={!!this.cvFile}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </RemoveButton>
                </div>
                {this.renderUploadSpinner()}
                {this.state.fileError && (
                  <div className="error-message">{this.state.fileError}</div>
                )}
                {this.state.dataImported && (
                  <div className="data-imported-message">
                    Daten erfolgreich importiert! ✔️
                  </div>
                )}
              </div>

              <EntryTabs
                prompts={this.prompts}
                currentPrompt={this.currentPrompt}
                onChange={this.handleCurrentPrompt}
              />

              {this.prompts.map((prompt, index) => (
                <EntryPrompt
                  prompt={prompt}
                  key={index}
                  index={index}
                  disabled={this.disabled}
                  currentPrompt={this.currentPrompt}
                >
                  {prompt.prompts.map((promptInput, index) => (
                    <EntryInput
                      prompt={promptInput}
                      key={index}
                      language={this.language}
                      index={index}
                      disabled={this.disabled}
                    />
                  ))}

                  {/* <div className="md:flex"> */}
                  <div className="flex items-center">
                    <div>
                      <Countdown
                        ref={(countdown) => (this.countdown[index] = countdown)}
                        date={this.date}
                        renderer={(props) => (
                          <Button
                            title={
                              props.total
                                ? `Timeout ${props.total / 1000} secs`
                                : this.buttonText
                            }
                            disabled={
                              props.total || this.isGenerateButtonDisabled
                            }
                            Icon={
                              props.total
                                ? ClockIcon
                                : currentValue
                                ? DuplicateIcon
                                : PencilIcon
                            }
                            onClick={this.onGenerateClick}
                          />
                        )}
                      />
                    </div>
                    {this.loading && (
                      <img
                        style={{
                          margin: "1em 2em 0 0",
                          height: "3em",
                          width: "3em",
                        }}
                        src={LoadingIcon}
                        alt="Loading"
                      />
                    )}
                    {/* <EntryN
                      prompts={this.prompts}
                      currentPrompt={this.currentPrompt}
                    /> */}
                  </div>

                  <p className="mt-2 ml-0 text-sm text-gray-500">
                    Bitte beachte, dass dir{" "}
                    <strong style={{ color: "black" }}>2 Credits</strong>{" "}
                    abgezogen werden, wenn du auf "Anschreiben generieren"
                    klickst.
                  </p>

                  {this.error && (
                    <div className="mt-4">
                      <label
                        className={`${
                          this.error ? "text-red-400" : "text-gray-400"
                        } font-medium transition-all`}
                      >
                        {this.error}
                      </label>
                    </div>
                  )}
                </EntryPrompt>
              ))}
            </Col>

            <Col span="6">
              <Output
                title={this.tool.output.title}
                desc={this.tool.output.desc}
                Icon={this.tool.output.Icon || this.tool.Icon}
                fromColor={this.tool.fromColor}
                toColor={this.tool.toColor}
                loading={this.loading}
                output={this.output}
                outputs={this.outputs}
                code={this.code}
                language={this.language}
                outputsColor={this.tool.output.color}
                OutputsIcon={this.tool.output.Icon}
              />

              {this.output && !this.feedbackSubmitted && (
                <>
                  <EntryText
                    title="Feedback"
                    desc="Wie zufrieden bist du mit dem Bewerbungsschreiben?"
                    prompt={this.props.store.feedback}
                    onChange={this.onChange}
                  />
                  <Countdown
                    ref={(countdown) => (this.countdownFeedback = countdown)}
                    date={this.date}
                    renderer={(props) => (
                      <ButtonFeedback
                        // title={
                        //   props.total
                        //     ? `Timeout ${props.total / 1000} secs`
                        //     : "Feedback abgeben"
                        // }
                        // disabled={props.total}
                        // Icon={props.total ? ClockIcon : PencilIcon}
                        title={"Feedback absenden"}
                        onClick={this.onClickFeedback}
                      />
                    )}
                  />
                </>
              )}
              {this.feedbackSubmitted && (
                <h2 className="text-center text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
                  Vielen Dank für dein Feedback! 😊
                </h2>
              )}
            </Col>
          </Grid>
        </Body>

        {/* Modals */}

        {this.renderProPlanWarningModal()}

        {this.renderCVUploadPremiumWarningModal()}

        {this.state.showModal && (
          <StyledModal className={this.state.showModal ? "open" : ""}>
            <Modal
              isOpen={this.state.showModal}
              onClose={() => this.setState({ showModal: false })}
              title="Kleiner Hinweis zu deinem Guthaben! ✨"
              message="Hey, es sieht so aus, als wären deine Credits aufgebraucht. 😊 Kein Problem, lade einfach dein Guthaben auf, um weiterhin alle Features nutzen zu können. Danke dir! Oder, falls du erstmal reinschnuppern möchtest, schau dir doch ein Beispiel an."
              exampleText="Beispiel ansehen"
              onExampleClick={this.onExample}
            />
          </StyledModal>
        )}

        {this.props.store.showCopyToClipboardModal && (
          <StyledModal className="open">
            <Modal
              isOpen={true}
              onClose={() =>
                this.props.store.setShowCopyToClipboardModal(false)
              }
              title="✨ Premium Feature"
              message="Diese Funktion steht ausschließlich unseren Premium-Nutzern zur Verfügung. Bitte erwägen ein Upgrade, um Zugang zu diesem exklusiven Feature zu erhalten."
            />
          </StyledModal>
        )}
      </>
    );
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledModal = styled.div`
  /* Andere Modal-Stile */

  &.open {
    animation: ${fadeIn} 0.5s forwards;
  }

  .upload-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Dunkler Hintergrund
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    p {
      color: white;
      margin-top: 20px;
    }
  }
`;

const StyledFileInput = styled.input`
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:hover {
    border-color: #80bdff;
  }

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const RemoveButton = styled.button`
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 1rem;
  background-color: ${(props) =>
    props.fileUploaded
      ? "#d3d4db"
      : "#d3d3d3"}; // Light gray when no file is uploaded
  color: ${(props) =>
    props.fileUploaded
      ? "white"
      : "black"}; // Adjust text color for better visibility
  border: none;
  border-radius: 0.25rem;
  cursor: ${(props) => (props.fileUploaded ? "pointer" : "not-allowed")};

  &:hover {
    background-color: ${(props) =>
      props.fileUploaded
        ? "#d32f2f"
        : "#d3d3d3"}; // Maintain light gray on hover when no file is uploaded
  }
`;

const throttle = (func, delay) => {
  let lastCallTimestamp = 0;

  return (...args) => {
    const currentTimestamp = Date.now();
    if (currentTimestamp - lastCallTimestamp >= delay) {
      lastCallTimestamp = currentTimestamp;
      func(...args);
    }
  };
};

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0; // Background of the container
  border-radius: 2px; // Adjusted for a thinner bar
  margin-top: 20px;
  overflow: hidden; // Ensures the inner bar doesn't exceed the border radius
`;

const ProgressBar = styled.div`
  height: 2px; // Making the bar thinner
  background-color: #007bff; // A shade of blue
  border-radius: 2px;
  width: ${(props) => props.width}%;
  transition: width 0.4s ease;
  box-shadow: 0 0 10px #007bff; // Adding a glow effect
`;

export default withRouter(Tool);

// This is a Tool component for a React application which leverages the mobx state management library and React Router. The component allows users to interact with an API and generates various outputs based on user input. Here are some key points about this component:

// State: This component is managing quite a bit of state including tool, prompts, currentPrompt, currentOption, error, output, outputs, code, loading, date, and countdown. This state is declared as @observable, which means it can be watched by mobx for changes.

// Lifecycle methods: The constructor fetches the specific tool from the store that matches the current URL path. If a tool doesn't exist for that path, it redirects to the home page ("/").

// Computed Properties: These are properties that are derived from the existing state (@computed). They include isGenerateButtonDisabled, disabled, isMinLength, and language.

// Event Handlers: This component defines several event handlers like onStartUsing, onExample, sanitizeAllPrompts, contentFilterFlagged, checkOutput, and onGenerateClick which control the flow and interactions of the component, such as sanitizing input, handling errors, and handling the generation of output.

// Render Method: The render method returns the JSX to be rendered by the component. This includes components like Header, Body, EntryTabs, EntryPrompt, EntryInput, Button, EntryN and Output. The Countdown component is used with a renderer to show a custom countdown timer.

// React Router: This component is wrapped in withRouter, a Higher Order Component (HOC) that connects the component with the router's history, match, and location objects. This allows the component to manipulate and read from the browser's URL.

// MobX Integration: The Tool component is decorated with @inject and @observer, making it part of the mobx system. @inject allows the component to get data from the store, while @observer makes the component re-render when any observed observables change.

// Content Filtering: The component uses the bad-words package to filter out any profane content from the prompts.

// Overall, this component seems to be a central piece of the application, offering a sophisticated, interactive tool interface for users.
