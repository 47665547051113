import React from "react";
import { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Logo from "../Logo";
import { observer, inject } from "mobx-react";
import { useHistory } from "react-router-dom";

const ForgotPassword = inject("store")(
  observer((props) => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    // const [forgotEmail, setForgotEmail] = useState("");
    const history = useHistory();

    function onChange(e) {
      setEmail(e.target.value);
    }

    const handleResetPassword = async (e) => {
      e.preventDefault();

      const data = {
        email: email,
      };

      try {
        const response = await props.store.api.post(
          "/auth/resetPassword",
          data
        );
        // console.log(response);

        if (response.data.success) {
          setSuccessMessage("Email versendet");
          props.store.setForgotEmail(email); // Save the email to mobx store
          history.push("/reset-password");

          //naviagate to the next Component
        } else {
          setErrorMessage(
            "There was an issue resetting your password. Please try again."
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <>
        <Helmet>
          <title>{`Login - AnschreibenAI`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="flex justify-center items-center mb-6">
            <div style={{ width: "50px", height: "50px", marginRight: "15px" }}>
              <Logo />
            </div>
            <div className="text-3xl md:text-5xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 mb-4">
              Anschreiben<span className="font-normal ">AI</span>
              {/* <div className="absolute top-0 ml-3 left-full bg-gradient-to-r from-gray-500 to-gray-500 text-white text-sm px-2 py-0.5 hidden md:inline-block rounded-md font-normal ">
                gpt4
              </div> */}
            </div>
          </div>
          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="align-bottom flex  transform transition-all sm:align-middle transition flex divide-x divide-gray-300 "></div>

            <div className="px-4 py-4 md:px-12 md:py-12">
              {/* Sorru */}

              <form onSubmit={handleResetPassword}>
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${
                    email ? "green" : "gray"
                  }-300  ${email ? "bg-green-300" : "bg-gray-300"} `}
                >
                  <LockClosedIcon
                    className={`h-8 w-8 ${"text-green-700"} text-${
                      email ? "green-700" : "gray-500"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center ">
                  <div className="text-3xl font-medium text-gray-900">
                    Passwort wiederherstellen
                  </div>
                  <p className="text-lg text-gray-500">
                    Bitte Emailadresse eingeben
                  </p>
                  <div className="flex flex-col flex-1">
                    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                      Email Addresse
                    </label>
                    <input
                      value={email}
                      onChange={onChange}
                      focus="true"
                      type="email"
                      className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                      placeholder="john@smith.com"
                    />
                  </div>

                  <div className="flex flex-col">
                    <button
                      type="submit"
                      className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block"
                    >
                      Passwort zurücksetzen anfordern
                    </button>
                  </div>
                </div>
              </form>

              {/* <Link to="/login" className="mt-4 text-gray-400 text-sm">
                Back to Login
              </Link> */}

              {errorMessage ? (
                <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
                  {errorMessage}
                </div>
              ) : null}
              {successMessage ? (
                <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                  {successMessage}
                </div>
              ) : null}
            </div>

            <Link
              to="/login"
              className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Zurück zum Login
            </Link>

            {/* <a
              href={`https://www.open.ai/`}
              className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Back to landing page
            </a> */}
          </div>
        </div>

        {/* ////////////////////////////////////////////////////////////    */}
      </>
    );
  })
);

export default ForgotPassword;
