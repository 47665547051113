import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";
import { inject, observer } from "mobx-react"; // Inject the store
import { diffWords } from "diff";
import Body, { Grid, Col } from "../Components/Body";
import EntryDropdown from "../Components/EntryDropdown";
import LoadingIcon from "../Core/Spin_Loading_Color.svg";

// Injecting the store into the KiModal component
const KiModal = inject("store")(
  observer(
    ({
      store,
      isOpen,
      onClose,
      content,
      title,
      onConfirm,
      limitCounter,
      setLimitCounter,
    }) => {
      const [newText, setNewText] = useState("");
      const [generatedText, setGeneratedText] = useState(""); // For handling generated text
      const [stil, setStil] = useState("");
      const [satzbau, setSatzbau] = useState("");
      const [tonalität, setTonalität] = useState("");
      const [inputError, setInputError] = useState(false); // New state for input error
      const [limitError, setLimitError] = useState(false); // New state for input error
      const [isLoading, setIsLoading] = useState(false);

      const customStyles = {
        control: (provided) => ({
          ...provided,
          minHeight: "30px", // Smaller height for the dropdown box
          fontSize: "14px", // Smaller font size for the text inside the dropdown
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: "0 8px", // Adjust padding inside the dropdown
        }),
        input: (provided) => ({
          ...provided,
          fontSize: "14px", // Font size for the input
        }),
        option: (provided) => ({
          ...provided,
          fontSize: "14px", // Font size for each option
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: "14px", // Font size for the selected value
        }),
      };

      const getDiff = (original, updated) => {
        return diffWords(original, updated);
      };

      // Funktion, um die Unterschiede zwischen Original und Neu anzuzeigen
      // Funktion, um die Unterschiede zwischen Original und Neu anzuzeigen
      const renderDiff = () => {
        if (!generatedText || generatedText === content) return null; // Zeige nichts an, wenn es keine Änderungen gibt
        const diff = diffWords(content, generatedText);
        return diff.map((part, index) => {
          const color = part.added ? "green" : part.removed ? "red" : "black";
          return (
            <span key={index} style={{ color }}>
              {part.value}
            </span>
          );
        });
      };

      // Reset newText when modal opens
      useEffect(() => {
        if (isOpen) {
          setNewText("");
          setGeneratedText(""); // Also clear generated text
        }
      }, [isOpen]);

      // Example API call to generate text using store's API
      const handleGenerate = async () => {
        if (limitCounter < 10) {
          try {
            setIsLoading(true);
            const response = await store.api.post("/generate-text", {
              inputText: newText, // Sending the new input text to the backend API
              contentText: content,
              stil: stil,
              tonalität: tonalität,
              satzbau: satzbau,
            });

            const data = response.data;
            setGeneratedText(data.generatedText); // Store the generated text response
            setLimitCounter((limitCounter) => limitCounter + 1);
            setIsLoading(false);
          } catch (error) {
            console.error("Error generating text:", error);
          }
        } else {
          console.log("Zu viele anfragen");
          setLimitError(true);
        }
      };

      const handleConfirm = () => {
        if (onConfirm) {
          onConfirm(generatedText || newText); // If there's generated text, use it, otherwise use the input
        }
      };

      if (!isOpen) return null;

      return createPortal(
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="modal"
          unmountOnExit
        >
          <ModalOverlay>
            <ModalContent>
              {/* <Body> */}
              <Grid>
                <Col span="6">
                  <ModalHeader />
                  {title && <ModalTitle>{title}</ModalTitle>}
                  {/* Tab mit dem Namen "Inputs" */}
                  {/* <TabButton onClick={() => setIsTabOpen(!isTabOpen)}>
                    <ArrowIcon isOpen={isTabOpen}>▶</ArrowIcon>
                    Inputs
                  </TabButton> */}
                  {/* Klappbarer Inhalt */}
                  {/* <CollapsibleContent isOpen={isTabOpen}> */}
                  {content && (
                    <SelectedTextContainer>{content}</SelectedTextContainer>
                  )}
                  <Label>Beschreibe was du anpassen möchtest (Optional)</Label>
                  <textarea
                    placeholder="Beschreibe hier, welche Änderungen du am Text wünschst, z. B. Stil: kreativer, Satzbau: aktiv, Ton: freundlicher Inhalt: ich habe 3 Jahre CAD Erfahrung ..."
                    value={newText}
                    onChange={(e) => setNewText(e.target.value)}
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      padding: "10px",
                      fontSize: "16px",
                      border: "1px solid #e2e8f0",
                    }}
                  />
                  {inputError && (
                    <ErrorText>
                      Bitte schreibe was du Verbessert haben möchtest.
                    </ErrorText>
                  )}
                  {/* Error message */}
                  {/* Dropdowns for Stil, Satzbau, Tonalität with labels */}
                  <EntryDropdownWrapper>
                    <Label>Tonalität (Optional)</Label>
                    <EntryDropdown
                      styles={customStyles}
                      onChange={(selectedOption) =>
                        setTonalität(selectedOption.value)
                      }
                      options={[
                        { value: "", label: "Keine Auswahl" },
                        { value: "freundlich", label: "Freundlich" },
                        { value: "neutral", label: "Neutral" },
                        { value: "streng", label: "Streng" },
                        { value: "formell", label: "Formell" },
                        { value: "Selbstbewusst", label: "Selbstbewusst" },
                        { value: "Informell", label: "Informell" },
                      ]}
                      placeholder="Tonalität auswählen"
                    />
                  </EntryDropdownWrapper>
                  <EntryDropdownWrapper>
                    <Label>Stil (Optional)</Label>
                    <EntryDropdown
                      styles={customStyles}
                      onChange={(selectedOption) =>
                        setStil(selectedOption.value)
                      }
                      options={[
                        { value: "", label: "Keine Auswahl" },
                        { value: "kreativ", label: "Kreativ" },
                        { value: "sachlich", label: "Sachlich" },
                        { value: "Professionell", label: "Professionell" },
                        { value: "Enthusiastisch", label: "Enthusiastisch" },
                        { value: "Motivational", label: "Motivational" },
                      ]}
                      placeholder="Stil auswählen"
                    />
                  </EntryDropdownWrapper>
                  <EntryDropdownWrapper>
                    <Label>Satzbau (Optional)</Label>
                    <EntryDropdown
                      styles={customStyles}
                      onChange={(selectedOption) =>
                        setSatzbau(selectedOption.value)
                      }
                      options={[
                        { value: "", label: "Keine Auswahl" },
                        { value: "aktiv", label: "Aktiv" },
                        { value: "neutral", label: "Neutral" },
                        { value: "Kurze Sätze", label: "Kurze Sätze" },
                        {
                          value: "Schachtelsätze vermeiden",
                          label: "Schachtelsätze vermeiden",
                        },
                        {
                          value: "Komplexe Sätze",
                          label: "Komplexe Sätze",
                        },
                      ]}
                      placeholder="Satzbau auswählen"
                    />
                  </EntryDropdownWrapper>
                  {/* </CollapsibleContent> */}
                </Col>

                <Col span="6">
                  {/* <TabButton
                    onClick={() => setIsOutputTabOpen(!isOutputTabOpen)}
                  >
                    <ArrowIcon isOpen={isOutputTabOpen}>▶</ArrowIcon>
                    Output
                  </TabButton> */}

                  {/* Klappbarer Inhalt */}
                  {/* <CollapsibleContent isOpen={isOutputTabOpen}> */}
                  {/* Display the generated text in a new textarea */}
                  <ModalHeader />
                  <ModalTitle>Optimierter Text:</ModalTitle>
                  <h3 style={{ marginButtom: "20px" }}></h3>
                  {isLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center", // Zentriert horizontal
                        alignItems: "center", // Zentriert vertikal
                        height: "100%", // Volle Höhe des sichtbaren Bereichs
                      }}
                    >
                      <img
                        style={{
                          height: "3em",
                          width: "3em",
                        }}
                        src={LoadingIcon}
                        alt="Loading"
                      />
                    </div>
                  )}

                  {generatedText && (
                    <>
                      <textarea
                        value={generatedText}
                        onChange={(e) => setGeneratedText(e.target.value)}
                        style={{
                          width: "100%",
                          minHeight: "500px",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      />

                      {/* <HintText>
                        Hinweis: Wenn Sie auf die Schaltfläche übernehmen
                        drücken, wird der komplette Text in Ihr Anschreiben
                        eingefügt. Wir empfehlen, alles nicht notwendige aus dem
                        oberen Text zu entfernen.
                      </HintText> */}
                    </>
                  )}
                </Col>
              </Grid>
              <ButtonContainer>
                <GenerateButton onClick={handleGenerate}>
                  Mit KI generieren
                </GenerateButton>

                <ConfirmButton
                  onClick={handleConfirm}
                  disabled={!generatedText} // Disable button if both are empty
                >
                  Übernehmen
                </ConfirmButton>
                {/* Angepasster Text mit Zähler */}
                <LimitCounterText>
                  <LimitNumber>{limitCounter} / 10</LimitNumber> Optimierungen
                  Übrig.
                </LimitCounterText>
                <CloseButton onClick={onClose}>Schließen</CloseButton>
              </ButtonContainer>

              {limitError && (
                <ErrorText>
                  Das Limit an Optimierungen für dieses Anschreiben wurde
                  erreicht.
                </ErrorText>
              )}

              {/* </Body> */}
            </ModalContent>
          </ModalOverlay>
        </CSSTransition>,
        document.getElementById("modal")
      );
    }
  )
);

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const LimitCounterText = styled.div`
  font-size: 16px; /* Standardgröße für Text */
  color: #2d3748; /* Textfarbe */
  font-weight: 500; /* Etwas fetterer Text */
  display: flex; /* Flexbox für horizontale Ausrichtung */
  align-items: center; /* Vertikale Zentrierung */
  margin-top: 2.2rem;
  margin-bottom: auto;
  margin-left: 1rem;
`;

const LimitNumber = styled.span`
  font-size: 18px; /* Größere Schrift für die Zahl */
  color: #6c52ff; /* Farbe der Zahl, passend zum Hauptdesign */
  font-weight: 700; /* Fettgedruckte Zahl */
  margin-right: 0.5rem; /* Kleiner Abstand zwischen Zahl und Text */
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #2d3748;
  font-weight: 500;
`;

const TabButton = styled.button`
  background-color: #f7fafc; /* Gleiche Hintergrundfarbe wie SelectedTextContainer */
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #6c52ff; /* Gleiche Hauptfarbe wie ModalTitle */
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 15px;
  width: 100%;
  justify-content: space-between;

  &:hover {
    background-color: #e2e8f0; /* Hover-Farbe, leicht dunkler */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(108, 82, 255, 0.4); /* Fokus mit einem leichten Schatten */
  }
`;

const ArrowIcon = styled.span`
  margin-left: 10px;
  font-size: 24px;
  transform: ${({ isOpen }) =>
    isOpen ? "rotate(90deg)" : "rotate(0deg)"}; /* Rotiert beim Öffnen */
  transition: transform 0.3s ease;
  color: #a0aec0; /* Helles Grau für das Symbol */
`;
const CollapsibleContent = styled.div`
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0")};
  transition: max-height 0.3s ease;
  border-top: 1px solid #e2e8f0;
  padding-top: ${({ isOpen }) => (isOpen ? "15px" : "0")};
  margin-top: ${({ isOpen }) => (isOpen ? "15px" : "0")};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HintText = styled.span`
  color: #a0aec0; /* Helles Grau */
  font-size: 14px; /* Kleine Schrift */
  display: block; /* Block-Element, um Abstand zu gewährleisten */
  margin-top: 10px; /* Abstand über dem Text */
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // Ensure modal is on top
`;
const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  font-size: 23px;
  width: 100%;
  max-width: 1200px;
  max-height: 80vh; // Set a max height for the modal
  overflow-y: auto; // Enable scrolling if content overflows
`;
const EntryDropdownWrapper = styled.div`
  position: relative;
  z-index: 2000; // Ensure dropdown is above other elements
`;

const ModalTitle = styled.h2`
  font-size: 26px;
  color: #6c52ff;
  margin-bottom: 15px;
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #6c52ff, #ff007a);
  border-radius: 5px 5px 0 0;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  margin-left: auto;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 23px;
  font-weight: 500;
  color: #6c52ff;
  margin-top: 2rem;
  &:hover {
    color: #1f2937;
  }
`;

const ConfirmButton = styled.button`
  background-color: #4a5568;
  border: none;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 8px;

  &:hover {
    background-color: #2d3748;
  }

  &:disabled {
    background-color: #a0aec0; /* Lighter color for disabled state */
    cursor: not-allowed;
  }
`;

const SelectedTextContainer = styled.div`
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  margin-bottom: 20px;
  color: #2d3748;
`;

const GenerateButton = styled.button`
  background: linear-gradient(to right, #6c52ff, #ff007a);
  border: none;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;

  margin-left: 8px;
  &:hover {
    background: #1f2937;
  }
`;

const DiffContainer = styled.div`
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 20px;
  color: #2d3748;
  max-height: 200px;
  overflow-y: auto;
`;

export default KiModal;
