import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const ButtonContainer = styled.div`
  display: flex;
  // justify-content: space-between; // Dies positioniert die ersten und letzten Kinder am Anfang bzw. am Ende
  align-items: center;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  font-size: 23px; // Schriftgröße erhöht

  width: 80%; // Dies stellt die Breite auf 80% des umgebenden Elternelements ein
  max-width: 650px; // Dies beschränkt die maximale Breite auf 500px
`;

const ModalTitle = styled.h2`
  font-size: 26px; // Sie können die Schriftgröße nach Bedarf anpassen.
  color: #6c52ff;
  margin-bottom: 15px; // Abstand hinzugefügt
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #6c52ff, #ff007a);
  border-radius: 5px 5px 0 0;
  margin-bottom: 20px; // Abstand hinzugefügt
`;

const CloseButton = styled.button`
  //   position: absolute; // Absolute positioning
  //   top: 10px; // Offset from the top
  //   right: 10px; // Offset from the right
  //margin-bottom: 20px; // Abstand hinzugefügt
  margin-left: auto; // Schiebt den Button nach rechts
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 23px;
  font-weight: 500;
  color: #6c52ff;
  margin-top: 2rem;
  &:hover {
    color: #1f2937;
  }
`;

// Weitere Styled-Komponenten
const ConfirmButton = styled.button`
  background-color: #f44336; // Rote Farbe für Abbrechen-Button
  border: none;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 8px;
  &:hover {
    background-color: #d32f2f; // Dunkleres Rot für Hover-Effekt
  }
`;

const CancelButton = styled.button`
  background-color: #4caf50; // Grüne Farbe für Bestätigen-Button
  border: none;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 8px;
  &:hover {
    background-color: #43a047; // Dunkleres Grün für Hover-Effekt
  }
`;

const ChargeButton = styled.button`
  margin-right: auto;
  background: linear-gradient(to right, #6c52ff, #ff007a);
  border: none;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;
  //margin-right: 0.5rem;
  margin-left: 8px;
  &:hover {
    background: #1f2937;
  }
`;

const ExampleButton = styled.button`
  background-color: #e2e8f0; // Ein sanftes Grau als Hintergrund
  border: none;
  border-radius: 0.375rem;
  color: #4a5568; // Ein dunkleres Grau für den Text
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 8px;
  &:hover {
    background-color: #cbd5e0; // Ein helleres Grau für den Hover-Effekt
  }
`;

const ModalDelete = ({
  isOpen,
  onClose,
  message,
  title,
  exampleText,
  onExampleClick,
  confirmText,
  cancelText,
  onConfirmClick,
  onCancelClick,
}) => {
  const history = useHistory(); // Verwenden Sie den Hook in Ihrer Komponente

  const navigateToPricing = () => {
    history.push("/signup/pricing");
  };

  if (!isOpen) return null;

  return (
    <CSSTransition in={isOpen} timeout={300} classNames="modal" unmountOnExit>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader></ModalHeader>
          {title && <ModalTitle>{title}</ModalTitle>}{" "}
          {/* Hier rendern wir den Titel, falls vorhanden */}
          <p>{message}</p>
          <ButtonContainer>
            {confirmText && (
              <ConfirmButton onClick={onConfirmClick}>
                {confirmText}
              </ConfirmButton>
            )}
            {cancelText && (
              <CancelButton onClick={onCancelClick || onClose}>
                {cancelText}
              </CancelButton>
            )}
            <CloseButton onClick={onClose}>Schließen</CloseButton>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
    </CSSTransition>
  );
};

export default ModalDelete;
