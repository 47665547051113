import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const ContextMenu = ({ position, onEdit, onAction }) => {
  return createPortal(
    <>
      <StyledContextMenu
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          console.log("ContextMenu clicked");
        }} // Ensure propagation is stopped and log clicks
      >
        <li
          onClick={(e) => {
            e.stopPropagation(); // Make sure this stops event bubbling too
            console.log("Edit clicked in context menu");
            onEdit(); // Ensure this is firing the correct function
          }}
        >
          Mit Ki anpassen
        </li>
        {/* <li onClick={onAction}>Another Action</li> */}
      </StyledContextMenu>
    </>,
    document.getElementById("modal")
  );
};

// Styled component for the context menu
const StyledContextMenu = styled.ul`
  position: absolute;
  border: none;
  background: linear-gradient(to right, #6c52ff, #ff007a);
  border-radius: 0.375rem;
  color: white;

  list-style-type: none;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2 10px rgba(0, 0, 0, 0.2);

  li {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px; /* Add padding to fill out the area */
    border-radius: 0.375rem;
    transition: background 0.3s ease; /* Smooth transition for hover */

    text-align: center; /* Center text */
  }

  li:hover {
    background: #1f2937;
    color: white; /* Ensure text remains white on hover */
  }
`;

export default ContextMenu;
