import React from "react";
import { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import { observer, inject } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Logo from "../Logo";

const ForgotPassword = inject("store")(
  observer((props) => {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const history = useHistory();

    function onChangePassword(e) {
      setPassword(e.target.value);
    }

    function onChangePassword2(e) {
      setPassword2(e.target.value);
    }

    function onChangeVerificationCode(e) {
      setVerificationCode(e.target.value);
    }

    const handleResendCode = async () => {
      if (props.store.forgotEmail) {
        const data = {
          email: props.store.forgotEmail,
        };

        try {
          const response = await props.store.api.post(
            "/auth/resetPassword",
            data
          );
          // console.log(response);

          if (response.data.success) {
            setSuccessMessage("Neuer Code wurde versendet.");
          } else {
            setErrorMessage(
              "There was an issue resetting your password. Please try again."
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        history.push("/forgot-password");
      }
    };

    const handleResetPassword = async (e) => {
      e.preventDefault();

      if (password !== password2) {
        setErrorMessage(
          "Passwörter sind nicht identisch. Versuchen Sie es bitte erneut."
        );
        return;
      }

      const data = {
        email: props.store.forgotEmail, // this Email i want to get from the store
        verificationCode: verificationCode,
        password: password,
      };

      // console.log(data);

      try {
        const response = await props.store.api.post(
          "/auth/resetPasswordConfirm",
          data
        );
        // console.log(response);

        if (response.data.success) {
          setSuccessMessage("Passwort wurde erfolgreich geändert");
          setErrorMessage("");

          props.store.setForgotEmail("");

          //Wait for 3 seconds, then redirect to the login page
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        } else {
          setErrorMessage(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <>
        <Helmet>
          <title>{`Login - AnschreibenAI`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="flex justify-center items-center mb-6">
            <div style={{ width: "50px", height: "50px", marginRight: "15px" }}>
              <Logo />
            </div>
            <div className="text-3xl md:text-5xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 mb-4">
              Anschreiben<span className="font-normal ">AI</span>
              {/* <div className="absolute top-0 ml-3 left-full bg-gradient-to-r from-gray-500 to-gray-500 text-white text-sm px-2 py-0.5 hidden md:inline-block rounded-md font-normal "></div> */}
            </div>
          </div>
          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="align-bottom flex  transform transition-all sm:align-middle transition flex divide-x divide-gray-300 "></div>

            <div className="px-4 py-4 md:px-12 md:py-12">
              {/* Sorru */}
              <form onSubmit={handleResetPassword}>
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${
                    password ? "green" : "gray"
                  }-300  ${password ? "bg-green-300" : "bg-gray-300"} `}
                >
                  <LockClosedIcon
                    className={`h-8 w-8 ${"text-green-700"} text-${
                      password ? "green-700" : "gray-500"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center ">
                  <div className="text-3xl font-medium text-gray-900">
                    Neues Passwort erstellen
                  </div>
                  <p className="text-lg text-gray-500">
                    Bitte Neues Passwort vergeben
                  </p>

                  <div className="flex flex-col flex-1">
                    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                      Verifizierungscode
                    </label>
                    <input
                      value={verificationCode}
                      onChange={onChangeVerificationCode}
                      type="text"
                      className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                      placeholder="5 Stelligen Code eingeben"
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                      Neues Passwort
                    </label>
                    <input
                      value={password}
                      onChange={onChangePassword}
                      type="password"
                      className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                      placeholder="Neues Passwort"
                    />
                  </div>

                  <div className="flex flex-col flex-1">
                    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                      Passwort wiederholen
                    </label>
                    <input
                      value={password2}
                      onChange={onChangePassword2}
                      type="password"
                      className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                      placeholder="Passwort wiederholen"
                    />
                  </div>

                  <div className="flex flex-col">
                    <button
                      type="submit"
                      className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block"
                    >
                      Passwort ändern
                    </button>
                  </div>
                </div>
              </form>

              <div className="flex flex-col">
                <button
                  type="button"
                  className="mt-4 text-gray-400 text-sm"
                  onClick={handleResendCode}
                >
                  Verifizierungscode erneut senden
                </button>
              </div>

              {errorMessage ? (
                <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
                  {errorMessage}
                </div>
              ) : null}
              {successMessage ? (
                <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                  {successMessage}
                </div>
              ) : null}
            </div>

            <Link
              to="/login"
              className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Zurück zum Login
            </Link>
          </div>
        </div>
      </>
    );
  })
);

export default ForgotPassword;
