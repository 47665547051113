import styled from "styled-components";

// fromColor={`yellow-300`}
// toColor={`yellow-400`}

const StyledButton = styled.button`
  background: linear-gradient(to right, #ecbd44, #f49814);
  border-top: 2px solid #cbd5e0;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  padding: 12px 24px;
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-right: 0; // For medium screens
  margin-left: 0; // For medium screens
  user-select: none; // corresponds to 'select-none'
  display: flex; // For all screens
  justify-content: center; // To center the content
  align-items: center; // To vertically center the content

  // You can add media query for medium screens if you need to change any styles:
  // @media (min-width: 768px) {
  //   ...styles for md screens
  // }

  &:hover {
    background: #1f2937;
  }

  &:disabled {
    background: #d1d5db;
    cursor: not-allowed;
  }
`;

// Added ButtonFeedback
function ButtonFeedback({ title, onClick, Icon, disabled, children }) {
  return (
    <StyledButton onClick={disabled ? null : onClick} disabled={disabled}>
      <div className="flex">
        {Icon && (
          <Icon
            className={
              disabled
                ? `h-6 w-6 mr-2 text-gray-100`
                : "h-6 w-6 mr-2 text-gray-200"
            }
          />
        )}
        {title}
        {children}
      </div>
    </StyledButton>
  );
}

export default ButtonFeedback;
