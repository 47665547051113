import { ClipboardListIcon } from "@heroicons/react/solid";
import descriptionText from "./descriptionText";

const obj = {
  title: "Arbeitszeugnis erstellen",
  desc: "Erstellen Sie ein professionelles Arbeitszeungis, passend auf Sie zugeschnitten.",
  category: "Tools",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/others/certificateOfEmployment",
  api: "/ai/others/certificateOfEmployment",
  apiCv: "/ai/upload-cv-motivationletter1",
  currentSchema: "arbeitszeugnis",

  fromColor: "blue-600",
  toColor: "yellow-500",

  output: {
    title: "Dein Arbeitszeugnis",
    desc: descriptionText,
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Dein Arbeitszeugnis",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Arbeitszeugnis. Vielen Dank! ",
      // n: 1,
      prompts: [
        {
          title: "Titel der Stellenbeschreibung",
          attr: "positionTitle",
          value: "",
          placeholder: "Mechatroniker",
          label: "Beispiel: Mechatroniker, Praktikum Verkäufer",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Entwicklungsingenieur Mechanik",
        },
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: Bosch GmbH, Siemens AG",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: "Firma XYZ",
        },
        {
          title: "Beschäftigugnszeitraum",
          attr: "date",
          value: "",
          type: "date-range",
          maxLength: false,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
        },
        {
          title: "Tätigkeitsbeschreibung",
          attr: "jobDescription",
          value: "",
          placeholder:
            "Beschreiben deine Aufgaben, Verantwortlichkeiten, Tätigkeiten " +
            "Zum Beispiel: Erstellung von...",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `Meine Aufgaben
            Versuchsdurchführungen und Auswertungen an Prüfständen
            Messungen und Tests diverser Materialeigenschaften
            Unterstützung bei unterschiedlichen Entwicklungsprojekten
            Montageunterstützung an verschiedenen Prüfständen
            Unterstützung der Ingenieure bei Konstruktionstätigkeiten
            Dokumentation sämtlicher Versuchs-/Messergebnisse
            IHR PROFIL            
             `,
        },

        {
          title: "Werkzeuge und Technologien (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "CAD, SolidWorks, Konstruktion, Programmierung ",
        },
        {
          title: "Leistungsbeurteilung (Optional)",
          attr: "skillRating",
          value: "Sehr gute Leistungen",
          //label: "DE: Deutsch, EN: Englisch",
          type: "dropdown",
          options: [
            { value: "Sehr gute Leistungen", label: "Sehr gute Leistungen" },
            {
              value: "Gute Leistungen",
              label: "Gute Leistungen",
            },
            {
              value: "Befriedigende Leistungen",
              label: "Befriedigende Leistungen",
            },
            {
              value: "Ausreichende Leistungen",
              label: "Ausreichende Leistungen",
            },
            {
              value: "Mangelhafte Leistungen",
              label: "Mangelhafte Leistungen",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
        },
        {
          title: "Verhaltensbeurteilung (Optional)",
          attr: "behaviorRating",
          value: "Sehr gutes Verhalten",
          //label: "DE: Deutsch, EN: Englisch",
          type: "dropdown",
          options: [
            { value: "Sehr gutes Verhalten", label: "Sehr gutes Verhalten" },
            {
              value: "Gutes Verhalten",
              label: "Gutes Verhalten",
            },
            {
              value: "Gutes Verhalten",
              label: "Gutes Verhalten",
            },
            {
              value: "Befriedigendes Verhalten",
              label: "Befriedigendes Verhalten",
            },
            {
              value: "Ausreichendes Verhalten",
              label: "Ausreichendes Verhalten",
            },
            {
              value: "Problematisches Verhalten",
              label: "Problematisches Verhalten",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
        },

        {
          title: "Sprache",
          attr: "language",
          value: "Deutsch",
          //label: "DE: Deutsch, EN: Englisch",
          type: "dropdown",
          options: [
            { value: "Deutsch", label: "Deutsch" },
            {
              value: "English",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
        },
      ],
      example: {
        output: `QUALIFIZIERTES ARBEITSZEUGNIS

        Herr [Ihr Name], geboren am [Ihr Geburtsdatum], war vom 1. April 2024 bis zum 30. April 2024 als Entwicklungsingenieur Mechanik in unserem Unternehmen Bosch GmbH tätig. 
        
        In dieser Position umfassten seine Hauptaufgabenbereiche die Durchführung und Auswertung von Versuchen an Prüfständen, Messungen und Tests diverser Materialeigenschaften, die Unterstützung bei diversen Entwicklungsprojekten sowie an verschiedenen Prüfständen. Darüber hinaus war er mit der Unterstützung der Ingenieure bei Konstruktionstätigkeiten sowie der Dokumentation sämtlicher Versuchs- und Messergebnisse betraut.
        
        Herr [Ihr Name] setzte während seiner Tätigkeit bei uns vor allem Werkzeuge und Technologien wie CAD und SolidWorks und seine Fähigkeiten in der Konstruktion und Programmierung erfolgreich ein. 
        
        Herr [Ihr Name] hat seine Aufgaben stets zu unserer vollsten Zufriedenheit erfüllt. Er zeigte ausgeprägtes technisches Verständnis und Lösungsorientierung. Er überzeugte durch seine schnelle Auffassungsgabe und die Fähigkeit, auch unter hohem Arbeitsaufkommen effizient und präzise zu arbeiten. 
        
        Sein Verhalten gegenüber Vorgesetzten und Kollegen war stets einwandfrei. Er war immer hilfsbereit und sein Umgang mit Kollegen und Vorgesetzten war stets von Respekt und Wertschätzung geprägt. Herr [Ihr Name] war ein geschätztes Mitglied unseres Teams.
        
        Wir bedauern sein Ausscheiden außerordentlich und danken ihm für seine hervorragende Arbeit und sein stets vorbildliches Engagement. Für seine berufliche und private Zukunft wünschen wir ihm weiterhin viel Erfolg und alles Gute. 
        
        Ort, Datum 
        
        [Unterschrift]
        [Position, Name des Vorgesetzten]
        Bosch GmbH`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
      // },
      // {
      //   title: "Bewerbungsschreiben Expert",
      //   desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank!",
      //   // n: 1,
      //   prompts: [
      //     {
      //       title: "Titel der Stellenbeschreibung",
      //       attr: "positionTitle",
      //       value: "",
      //       placeholder: "Mechatroniker",
      //       label: "Beispiel: Praktikum Online-Marketing, Praktikum Verkäufer",
      //       // type: "textarea",
      //       maxLength: 150,
      //       // max: 100,
      //       // min: 10,
      //       required: true,
      //       error: "",
      //       example: "Entwicklungsingenieur Mechanik",
      //     },
      //     {
      //       title: "Name der Firma (Optional)",
      //       attr: "companyName",
      //       value: "",
      //       placeholder: "Bitte Firmen Name eingeben...",
      //       label: "Beispiel: Bosch GmbH, Siemens AG",
      //       // type: "textarea",
      //       maxLength: 150,
      //       // max: 100,
      //       // min: 1,
      //       required: true,
      //       error: "",
      //       example: "Bosch GmbH",
      //     },
      //     {
      //       title: "Name des Ansprechpartners (Optional)",
      //       attr: "nameHiringManager",
      //       value: "",
      //       placeholder: " Bitte Name eingeben...",
      //       label: "",
      //       // type: "textarea",
      //       maxLength: 150,
      //       // max: 100,
      //       // min: 3,
      //       // required: false,
      //       error: "",
      //       example: "Frau Müller",
      //     },
      //     {
      //       title: "Quelle der Stellenanzeige (Optional)",
      //       attr: "sourceListing",
      //       value: "",
      //       placeholder: "Wo hast du die Stellenanzeige gefunden?",
      //       label: "Beispiel: Stepstone, Xing",

      //       maxLength: 150,
      //       // max: 100,
      //       // min: 3,
      //       required: false,
      //       error: "",
      //       example: "indeed.com",
      //     },
      //     {
      //       title: "Stellenbeschreibung",
      //       attr: "jobDescription",
      //       value: "",
      //       placeholder:
      //         "Hier kannst du die die komplette Stellenanzeige hineinkopieren. " +
      //         "Zum Beispiel: Was wir erwarten: Mit einem Schulpraktikum hast du bei uns die Möglichkeit Einblicke in den Beruf...",
      //       // label: "Examples: 40k, $20,000, $50 per hour",
      //       type: "textarea",
      //       maxLength: 2500,
      //       // max: 100,
      //       min: 50,
      //       required: true,
      //       error: "",
      //       example: `IHRE AUFGABEN

      //         Versuchsdurchführungen und Auswertungen an Prüfständen
      //         Messungen und Tests diverser Materialeigenschaften
      //         Unterstützung bei unterschiedlichen Entwicklungsprojekten
      //         Montageunterstützung an verschiedenen Prüfständen
      //         Unterstützung der Ingenieure bei Konstruktionstätigkeiten
      //         Dokumentation sämtlicher Versuchs-/Messergebnisse
      //         IHR PROFIL

      //         Technisches Studium (Universität/ FH) der Ingenieurswissenschaften
      //         Analytische Fähigkeiten sowie selbstständiges und strukturiertes Arbeiten
      //         Ausgeprägte Team-/Kommunikationsfähigkeit sowie Eigeninitiative und Flexibilität
      //         SAP- und CAD- Kenntnisse (Solid Edge) von Vorteil
      //         Gute Englischkenntnisse
      //         Mögliche Starttermine: ab Wintersemester 2023 `,
      //     },

      //     {
      //       title: "Dein Status (Optional)",
      //       attr: "myStatus",
      //       value: "",
      //       placeholder:
      //         "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
      //       label: "",
      //       type: "textarea",
      //       maxLength: 2500,
      //       // max: 100,
      //       // min: 3,
      //       required: false,
      //       error: "",
      //       example:
      //         "Ich bin im 3 Semester meines Bachelorstudiums an der FAU Erlangen/Nuernberg",
      //     },
      //     {
      //       title: "Dein Interesse (Optional)",
      //       attr: "myInterest",
      //       value: "",
      //       placeholder:
      //         "Erzähle kurz darüber, wieso du dich für diese Stelle bewerben möchtest? Worin liegt deine Hauptmotivation? ",
      //       label: "",
      //       type: "textarea",
      //       maxLength: 2500,
      //       // max: 100,
      //       // min: 3,
      //       required: false,
      //       error: "",
      //       example:
      //         "Ich habe eine große Begeisterung für Elektronik und Mechanik. ",
      //     },

      //     {
      //       title: "Deine Berufserfahrung (Optional)",
      //       attr: "myExperience",
      //       value: "",
      //       placeholder: `Erzähle über deine relevante Berufserfahrung,`,
      //       //label: "Optional",
      //       type: "textarea",
      //       maxLength: 600,
      //       // max: 100,
      //       // min: 1,
      //       required: false,
      //       error: "",
      //       example: `6 Monate Praktikum bei Tecosim im Bereich Simulation`,
      //     },
      //     {
      //       title: "Deine Qualifikation",
      //       attr: "myEducation",
      //       value: "",
      //       placeholder: "Erzähle kurz über deinen Werdegang... ",
      //       label: "Beispiel: 2010 - 2016 Bachelor in Informatik",
      //       label: "",
      //       type: "textarea",
      //       maxLength: 600,
      //       // max: 100,
      //       // min: 1,
      //       required: true,
      //       error: "",
      //       example: "2010 - 2016 Bachelor in Informatik",
      //     },
      //     {
      //       title: "Deine Fähigkeiten (Optional)",
      //       attr: "mySkills",
      //       value: "",
      //       placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //       label: "",
      //       type: "textarea",
      //       maxLength: 600,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "CAD, SolidWorks, Konstruktion, Programmierung ",
      //     },
      //     {
      //       title: "Sprache",
      //       attr: "language",
      //       value: "",
      //       label: "Examples: Accountant, Developer, Dentist, Receptionist",
      //       type: "multi-select",
      //       options: [
      //         { value: "DE", label: "Deutsch" },
      //         {
      //           value: "EN",
      //           label: "English",
      //         },
      //         {
      //           value: "abc",
      //           label: "abc",
      //         },
      //       ],
      //       // maxLength: 40,
      //       // max: 100,
      //       // min: 10,
      //       required: true,
      //       error: "",
      //       example: "DE",
      //     },
      //   ],
      //   example: {
      //     output: `Sehr geehrte Frau Müller,

      //     hiermit bewerbe ich mich um die auf indeed.com ausgeschriebene Stelle als Entwicklungsingeniuer Technische Mechanik bei der Bosch GmbH, wie auf indeed.com ausgeschrieben. Ich befinde mich derzeit im dritten Semester meines Bachelorstudiums der Ingenieurwissenschaften an der Hochschule Hof.

      //     An dieser Stelle bin ich besonders interessiert, da ich hier meine CAD-Kenntnisse, sowie meine Kenntnisse in der Konstruktion und Programmierung einsetzen kann. Ich habe bereits 6 Monate Erfahrung im Bereich der Simulation bei Tecosim gesammelt und bin überzeugt, dass mich diese Erfahrung ausreichend auf die Aufgabe vorbereitet hat.

      //     Neben meinen technischen Fähigkeiten verfüge ich auch über ausgeprägte analytische Fähigkeiten und ausgezeichnete Kommunikations- und Teamfähigkeiten. Ich bin hoch motiviert und proaktiv und habe eine große Leidenschaft für technische Mechanik. Als Beweis dafür habe ich ein Projekt im Bereich der Konstruktion abgeschlossen und arbeite derzeit an einem Projekt im Bereich der Robotik.

      //     Ich bin überzeugt, dass meine Ausbildung, meine Fähigkeiten und meine bisherigen Erfahrungen mich zu einem idealen Kandidaten für diese Stelle machen. Ich freue mich darauf, zum Team beizutragen und meine Kenntnisse in diesem Bereich weiter auszubauen.

      //     Vielen Dank, dass Sie meine Bewerbung berücksichtigt haben.

      //     Mit freundlichen Grüßen,
      //     [Ihr Name]`,
      //     // outputs: [],
      //     // Icon: RefreshIcon,
      //     // color: "",
      //   },
    },
  ],
};

export default obj;
