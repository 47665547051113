const dev = {
  baseURL: "http://localhost:3080/api/",
  landingPageUrl: "http://localhost:3080",
  stripe: {
    free: "price_1NChMgLDDCRmkxq1eCNW8veU",
    entry: "price_1NEroTEljjE2sKNlZTxzEPFN",
    pro: "price_1NpyBEEljjE2sKNloAXCjlBD",
    ultimate: "price_1Npy8xEljjE2sKNlzSJnHY67",
  },
};

const prod = {
  baseURL: "/api/",
  landingPageUrl: "https://anschreibenai.de",
  stripe: {
    free: "price_1NChMgLDDCRmkxq1eCNW8veU",
    entry: "price_1NEriWEljjE2sKNl0F6BMcMi",
    pro: "price_1NqKjZEljjE2sKNlEWEIHXu7",
    ultimate: "price_1Npy6dEljjE2sKNlk2y36VE7",
  },
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
