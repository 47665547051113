import React from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Logo from "../Logo";

@inject("store")
@observer
class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidToken: false,
      isLoading: true,
    };
  }

  verifyEmailToken = async (email, emailToken) => {
    // console.log("testing function");
    // console.log("email {0}", email);
    // console.log("token {0}", emailToken);
    const usernameAndToken = {
      email: email,
      emailToken: emailToken,
    };

    await this.props.store.api
      .post("/auth/verifyEmailToken", usernameAndToken)
      .then((response) => {
        console.log(response);
        const responseStatus = response.data.status;
        if (responseStatus === "okay") {
          this.setState({
            isValidToken: true,
          });

          // Treat user as logged in with the received token and profile
          this.props.store.loginWithDataTokenAndProfile({
            token: response.data.token,
            profile: response.data.profile,
          });

          // Redirect the user to the dashboard or home page
          //this.props.history.push("/"); // update this to your dashboard or main page route

          //Wait for 3 seconds, then redirect to the login page
          setTimeout(() => {
            this.props.history.push("/");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount() {
    const { email, token } = this.props.match.params;
    this.verifyEmailToken(email, token);
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh", // adjust this value as needed
          }}
        >
          <div className="flex justify-center items-center mb-6">
            <div style={{ width: "50px", height: "50px", marginRight: "15px" }}>
              <Logo />
            </div>
            <div className="text-3xl md:text-5xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 mb-4">
              Anschreiben<span className="font-normal ">AI</span>
            </div>
          </div>

          {this.state.isLoading ? (
            <div className="text-3xl font-medium text-gray-900">
              E-Mail wird verifiziert...
            </div>
          ) : this.state.isValidToken ? (
            <div>
              <div className="mt-3 text-center ">
                <div className="text-3xl font-medium text-gray-900">
                  E-Mail wurde verifiziert, Sie können sich jetzt anmelden
                </div>

                <Link to="/login" className="mt-4 text-gray-400 text-sm">
                  Zurück zum Login
                </Link>
              </div>
            </div>
          ) : (
            <div>
              E-Mail konnte nicht verifiziert werden oder das Token ist nicht
              mehr gültig
            </div>
          )}
        </div>
      </>
    );
  }
}

export default EmailVerification;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { observer, inject } from "mobx-react";

// const EmailVerification = inject("store")(observer((props) => {
//   const [isValidToken, setIsValidToken] = useState(false);

//   const verifyEmailToken = async (email, emailToken) => {
//     console.log("testing function");
//     console.log("email {0}", email);
//     console.log("token {0}", emailToken);
//     const usernameAndToken = {
//       email: email,
//       emailToken: emailToken,
//     };

//     try {
//       const response = await props.store.api.post("/auth/verifyEmailToken", usernameAndToken);
//       console.log(response);
//       const responseStatus = response.data.status;
//       if (responseStatus === "okay") {
//         setIsValidToken(true);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     const { fname, token } = props.match.params;
//     verifyEmailToken(fname, token);
//   }, []); // Empty dependency array so this effect only runs on component mount.

//   return (
//     <>
//       {isValidToken ? (
//         <div>
//           {" "}
//           Email has been verified you can now sign in
//           <Link to="/login">Login</Link>
//         </div>
//       ) : (
//         <div>Could not verify email or token is no longer valid</div>
//       )}
//     </>
//   );
// }));

// export default EmailVerification;
