import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import { NavLink } from "react-router-dom";
import { DatabaseIcon, UserCircleIcon } from "@heroicons/react/outline";

import { IconDashboard } from "./Icons";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import { useLocation } from "react-router-dom";
import Body from "./Components/Body";
import { withRouter } from "react-router-dom";
// import SvgComponent from "./SvgComponent";

function HeaderExpand(props) {
  const location = useLocation();
  return (
    <SuperHeader active={location.pathname === "/" ? true : false}>
      {props.children}
    </SuperHeader>
  );
}

@inject("store")
@observer
class SidebarCompontent extends Component {
  constructor(props) {
    super(props);
    if (this.props.location.pathname === "/signup") {
      this.props.history.push("/");
    }
    if (this.props.location.pathname === "/login") {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.shortcutHandler);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.shortcutHandler);
  }
  shortcutHandler = (e) => {
    if (e.keyCode === 75 && e.ctrlKey) {
      e.preventDefault();
      // select all text in input with id q
      document.getElementById("q").focus();
      document.getElementById("q").select();
    }
  };

  onKeyUp = (e) => {
    if (this.props.location.pathname !== "/search") {
      this.props.history.push("/search");
    }
    if (e.keyCode === 8) {
      if (this.props.store.toolsKeyword === "") {
        this.props.history.push("/");
      }
    }
  };

  handleButtonClick = () => {
    this.props.history.push("/signup/pricing");
  };

  @computed get fromColor() {
    if (this.props.store.profile.credits <= 0) {
      return "bg-red-200 text-red-600";
    }
    if (this.props.store.profile.status === "trialing") {
      return "";
    }
    if (this.props.store.profile.status === "active") {
      return "";
    }
    if (this.props.store.profile.status === "incomplete") {
      return "";
    }
    return "bg-red-200 text-red-600";
  }

  render() {
    return (
      <>
        <Textarea
          readOnly
          name="copy-textarea"
          id="copy-textarea"
          value={this.props.store.copyToClipboardText}
        />
        <HeaderExpand>
          <Body className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1">
            <div className="mr-4">
              <NavLink to="/">
                <Logo />
                {/* <SvgComponent /> */}
              </NavLink>
            </div>
            <div>
              <div className="text-4xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 inline-block">
                Anschreiben
                <span className="font-normal ">AI </span>
                {/* <div className="absolute top-0 ml-3 left-full bg-gradient-to-br from-gray-500 to-gray-500 text-white text-sm px-2 py-0.5 rounded-md font-normal hidden md:flex"></div> */}
              </div>
              <div className="hidden md:block text-xl text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-500 inline-block">
                Einfach Anschreiben erstellen für jeden Anlass
              </div>
              <div className="flex">
                <div
                  className={`items-center flex inline-flex ${
                    this.props.store.profile.credits
                      ? " bg-green-100 text-green-600"
                      : " bg-red-100 text-red-500"
                  } text-sm rounded-md px-3 py-1 font-medium my-2 mr-2`}
                >
                  <DatabaseIcon className="w-4 h-4 mr-2" />
                  {this.props.store.profile.credits}&nbsp;
                  <span className="hidden lg:block">Credits übrig</span>
                </div>

                {!this.props.store.profile.credits && (
                  <ChargeButton onClick={this.handleButtonClick}>
                    Guthaben aufladen
                  </ChargeButton>
                )}
              </div>
            </div>
          </Body>
        </HeaderExpand>
        <div className="border-b border-gray-300 bg-white shadow-sm ">
          <div className="container flex mx-auto px-4 md:px-28 flex select-none">
            <NavLink
              to="/"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
            >
              <IconDashboard className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">Home</div>
            </NavLink>

            <NavLink
              to="/history"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
            >
              <RestoreOutlinedIcon className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">Historie</div>
            </NavLink>

            <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 ">
              <label
                htmlFor="q"
                className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2 "
              >
                <div
                  type="submit"
                  className="p-2 focus:outline-none focus:shadow-outline "
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 transition"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </div>
              </label>
              <Input
                type="search"
                tabIndex={-1}
                id="q"
                name="q"
                className="py-4 pl-4 md:pl-14 text-xl focus:outline-none focus:bg-white focus:text-gray-900 transition flex flex-1 w-full"
                placeholder="Suche...  [Shortcut: Ctrl + K]"
                autoComplete="off"
                value={this.props.store.toolsKeyword}
                onChange={this.props.store.onChangeToolsKeyword}
                onKeyUp={this.onKeyUp}
              />
            </div>
            <NavLink
              to="/my-profile"
              exact
              tabIndex="-1"
              activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
              className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}
            >
              <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block"> Mein Profil</div>
            </NavLink>
          </div>
        </div>
        {this.props.children}
      </>
    );
  }
}

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="w-20 h-20"
    viewBox="0 0 232.59 261.31"
  >
    <defs>
      <linearGradient
        id="c"
        x1={136.61}
        x2={141.35}
        y1={75.64}
        y2={17.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9429" />
        <stop offset={1} stopColor="#f4d63b" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={60.7}
        x2={97.55}
        y1={59.98}
        y2={175.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#ff00c1" />
        <stop offset={0.43} stopColor="#d41bd3" />
        <stop offset={1} stopColor="#9d3deb" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={139.63}
        x2={84.54}
        y1={125.36}
        y2={93.61}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#81578d" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={96.9}
        x2={152.92}
        y1={153.34}
        y2={153.34}
        gradientTransform="rotate(40.53 140.989 94.071)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3b3f42" />
        <stop offset={1} stopColor="#121322" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="e"
        x1={213.3}
        x2={135.73}
        y1={201.58}
        y2={237.26}
      />
      <linearGradient
        xlinkHref="#b"
        id="f"
        x1={81.8}
        x2={83.69}
        y1={227.51}
        y2={267.23}
      />
    </defs>
    <g
      data-name="Ebene 2"
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M148 77.56c-22.91 13.79-45.27 27.57-71.11 27.3 0-.18-4.26-23.61 26.07-73.24C132.69 12.1 166.52-.1 200.73 0A588.64 588.64 0 0 0 148 77.56Z"
        style={{
          fill: "url(#c)",
        }}
      />
      <path
        d="M142.18 92.6a361.42 361.42 0 0 1-38 7.48 62.22 62.22 0 0 0 12-11.11c11.66-14.88 19.28-33.09 29.36-52.67-22.42 7-40.71 17.56-53.87 30.44a109.59 109.59 0 0 0-11.23 12.89c.28-13.29 2.48-27.47 8.55-41.28C64.55 56.31 49 86 43.62 118.44c-.48-26.1 10.44-52.5 17.2-64.69-33.63 36.73-55.6 80.3-42.17 124.93-1.78-5.2-11.1-22.28-18.65-25.82 12.85 18.38 19.61 67.84 19.61 67.84-.26-1.43 3.69-5 3.69-5 8.87-5.89 31.08-10 43.74-7.23-5.77-4.58-16-6.25-16-6.25 22.57-3.62 41.05-18.64 56-37.57-20.15 18.18-47.75 18.12-47.75 18.12C93 178.56 120 144 138.51 114.24 117.09 128.71 92 122.7 92 122.7c26.88-4.22 33.55-8.16 50.18-30.1Z"
        style={{
          fill: "url(#a)",
        }}
      />
      <path
        d="M199.28 1.83C107 44.78 55.57 127.53 30.46 191.39A199.8 199.8 0 0 0 22.92 216l.38-.35c8.87-5.89 31.08-10 43.74-7.23-5.77-4.58-16-6.25-16-6.25 22.57-3.62 41.05-18.64 56-37.57-20.15 18.18-47.75 18.12-47.75 18.12C93 178.56 120 144 138.51 114.24 117.09 128.71 92 122.7 92 122.7c26.91-4.22 33.58-8.16 50.21-30.1a361.76 361.76 0 0 1-37.64 7.4c14.93-5.15 29.08-13.79 43.43-22.44a589.42 589.42 0 0 1 51.28-75.73Z"
        style={{
          mixBlendMode: "multiply",
          fill: "url(#d)",
        }}
      />
      <path
        d="M174.39 16.17c-20.87 15.9-47.34 37.83-68.15 60.76a392.81 392.81 0 0 0-33.68 41.95c-29.36 42.21-49.94 91.8-58.62 141.9-.27 1.08-1.15.18-1-.56 0 0 .07-1.37.21-3.93 1.08-20.73 6.27-44.47 13.58-65.11 15.82-44 42.91-85.12 75.61-118.47 23.42-24 54.36-48 80.7-63.12l-8.58 6.54Z"
        style={{
          fill: "url(#b)",
        }}
      />
      <path
        d="m232.59 224.48-46.69 21.87a159 159 0 0 1-32.71 11.15 157.77 157.77 0 0 1-34.5 3.81H13.45l51.68-23.07a158.67 158.67 0 0 1 64.58-13.76Z"
        style={{
          fill: "url(#e)",
        }}
      />
      <path
        d="M153.19 257.5a157.77 157.77 0 0 1-34.5 3.81H13.45l51.68-23.07a158 158 0 0 1 39.27-11.72Z"
        style={{
          fill: "url(#f)",
        }}
      />
    </g>
  </svg>
);

const Input = styled.input``;

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

// transition:ease-in-out 0.5s margin-top;
// 	transition-delay:0.05s;

const SuperHeader = styled.div`
  height: 150px;
  background: white;
  margin-top: ${(props) => (props.active ? "0px" : "-150px")};
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
  background-image: url(${require("./pattern-dots.svg").default});
  background-size: auto 50%;
  background-position: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
`;

const ChargeButton = styled.button`
  background: linear-gradient(to right, #6c52ff, #ff007a);
  border: none;
  border-radius: 0.375rem; // translates to rounded-md in Tailwind
  color: white;
  cursor: pointer;
  padding: 0.25rem 0.75rem; // translates to py-1 and px-3 in Tailwind
  font-size: 0.875rem; // translates to text-sm in Tailwind
  font-weight: 500; // translates to font-medium in Tailwind
  margin-top: 0.5rem; // translates to my-2 in Tailwind
  margin-right: 0.5rem; // translates to mr-2 in Tailwind
  margin-left: 8px; // added based on your original styled-component
  &:hover {
    background: #1f2937; // explicitly use 'background' instead of 'background-color'
  }
`;

export default withRouter(SidebarCompontent);
