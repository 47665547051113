import { ClipboardListIcon } from "@heroicons/react/solid";
import descriptionText from "./descriptionText";

const obj = {
  title: "Bewerbungsschreiben als Unternehmensberater",
  desc: "Erstellen Sie ein professionelles Motivationsschreiben für deinen Traumjob.",
  category: "Job",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/job/consulting",
  api: "/ai/job/consulting",
  apiCv: "/ai/upload-cv-motivationletter1",
  currentSchema: "motivationletter1",

  fromColor: "blue-600",
  toColor: "yellow-500",

  output: {
    title: "Dein Bewerbungsschreiben",
    desc: descriptionText,
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Bewerbungsschreiben Classic",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank! ",
      // n: 1,
      prompts: [
        {
          title: "Titel der Stellenbeschreibung",
          attr: "positionTitle",
          value: "",
          placeholder: "Mechatroniker",
          label: "Beispiel: Praktikum Online-Marketing, Praktikum Verkäufer",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Entwicklungsingenieur Mechanik",
        },
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: Bosch GmbH, Siemens AG",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "Bosch GmbH",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "nameHiringManager",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },
        {
          title: "Quelle der Stellenanzeige (Optional)",
          attr: "sourceListing",
          value: "",
          placeholder: "Wo hast du die Stellenanzeige gefunden?",
          label: "Beispiel: Stepstone, Xing",

          maxLength: 150,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "indeed.com",
        },
        {
          title: "Stellenbeschreibung",
          attr: "jobDescription",
          value: "",
          placeholder:
            "Hier kannst du die die komplette Stellenanzeige hineinkopieren. " +
            "Zum Beispiel: Was wir erwarten: Mit einem Schulpraktikum hast du bei uns die Möglichkeit Einblicke in den Beruf...",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `IHRE AUFGABEN

            Versuchsdurchführungen und Auswertungen an Prüfständen
            Messungen und Tests diverser Materialeigenschaften
            Unterstützung bei unterschiedlichen Entwicklungsprojekten
            Montageunterstützung an verschiedenen Prüfständen
            Unterstützung der Ingenieure bei Konstruktionstätigkeiten
            Dokumentation sämtlicher Versuchs-/Messergebnisse
            IHR PROFIL
            
            Technisches Studium (Universität/ FH) der Ingenieurswissenschaften
            Analytische Fähigkeiten sowie selbstständiges und strukturiertes Arbeiten
            Ausgeprägte Team-/Kommunikationsfähigkeit sowie Eigeninitiative und Flexibilität
            SAP- und CAD- Kenntnisse (Solid Edge) von Vorteil
            Gute Englischkenntnisse
            Mögliche Starttermine: ab Wintersemester 2023 `,
        },

        {
          title: "Dein Status (Optional)",
          attr: "myStatus",
          value: "",
          placeholder:
            "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich bin im 3 Semester meines Bachelorstudiums an der FAU Erlangen/Nuernberg",
        },
        {
          title: "Dein Interesse (Optional)",
          attr: "myInterest",
          value: "",
          placeholder:
            "Erzähle kurz darüber, wieso du dich für diese Stelle bewerben möchtest? Worin liegt deine Hauptmotivation? ",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe eine große Begeisterung für Elektronik und Mechanik. ",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung,`,
          //label: "Optional",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: `6 Monate Praktikum bei Tecosim im Bereich Simulation`,
        },
        {
          title: "Deine Qualifikation",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deinen Werdegang... ",
          label: "Beispiel: 2010 - 2016 Bachelor in Informatik",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "2010 - 2016 Bachelor in Informatik",
        },
        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "CAD, SolidWorks, Konstruktion, Programmierung ",
        },
        {
          title: "Sprache",
          attr: "language",
          value: "",
          //label: "DE: Deutsch, EN: Englisch",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Frau Müller, 

        hiermit bewerbe ich mich um die auf indeed.com ausgeschriebene Stelle als Entwicklungsingeniuer Technische Mechanik bei der Bosch GmbH, wie auf indeed.com ausgeschrieben. Ich befinde mich derzeit im dritten Semester meines Bachelorstudiums der Ingenieurwissenschaften an der Hochschule Hof. 
        
        An dieser Stelle bin ich besonders interessiert, da ich hier meine CAD-Kenntnisse, sowie meine Kenntnisse in der Konstruktion und Programmierung einsetzen kann. Ich habe bereits 6 Monate Erfahrung im Bereich der Simulation bei Tecosim gesammelt und bin überzeugt, dass mich diese Erfahrung ausreichend auf die Aufgabe vorbereitet hat.
        
        Neben meinen technischen Fähigkeiten verfüge ich auch über ausgeprägte analytische Fähigkeiten und ausgezeichnete Kommunikations- und Teamfähigkeiten. Ich bin hoch motiviert und proaktiv und habe eine große Leidenschaft für technische Mechanik. Als Beweis dafür habe ich ein Projekt im Bereich der Konstruktion abgeschlossen und arbeite derzeit an einem Projekt im Bereich der Robotik. 
        
        Ich bin überzeugt, dass meine Ausbildung, meine Fähigkeiten und meine bisherigen Erfahrungen mich zu einem idealen Kandidaten für diese Stelle machen. Ich freue mich darauf, zum Team beizutragen und meine Kenntnisse in diesem Bereich weiter auszubauen. 
        
        Vielen Dank, dass Sie meine Bewerbung berücksichtigt haben. 
        
        Mit freundlichen Grüßen, 
        [Ihr Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
    {
      title: "Bewerbungsschreiben Expert",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank!",
      // n: 1,
      prompts: [
        {
          title: "Titel der Stellenbeschreibung",
          attr: "positionTitle",
          value: "",
          placeholder: "Mechatroniker",
          label: "Beispiel: Praktikum Online-Marketing, Praktikum Verkäufer",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Entwicklungsingenieur Mechanik",
        },
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: Bosch GmbH, Siemens AG",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "Bosch GmbH",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "nameHiringManager",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },
        {
          title: "Quelle der Stellenanzeige (Optional)",
          attr: "sourceListing",
          value: "",
          placeholder: "Wo hast du die Stellenanzeige gefunden?",
          label: "Beispiel: Stepstone, Xing",

          maxLength: 150,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "indeed.com",
        },
        {
          title: "Stellenbeschreibung",
          attr: "jobDescription",
          value: "",
          placeholder:
            "Hier kannst du die die komplette Stellenanzeige hineinkopieren. " +
            "Zum Beispiel: Was wir erwarten: Mit einem Schulpraktikum hast du bei uns die Möglichkeit Einblicke in den Beruf...",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `IHRE AUFGABEN

            Versuchsdurchführungen und Auswertungen an Prüfständen
            Messungen und Tests diverser Materialeigenschaften
            Unterstützung bei unterschiedlichen Entwicklungsprojekten
            Montageunterstützung an verschiedenen Prüfständen
            Unterstützung der Ingenieure bei Konstruktionstätigkeiten
            Dokumentation sämtlicher Versuchs-/Messergebnisse
            IHR PROFIL
            
            Technisches Studium (Universität/ FH) der Ingenieurswissenschaften
            Analytische Fähigkeiten sowie selbstständiges und strukturiertes Arbeiten
            Ausgeprägte Team-/Kommunikationsfähigkeit sowie Eigeninitiative und Flexibilität
            SAP- und CAD- Kenntnisse (Solid Edge) von Vorteil
            Gute Englischkenntnisse
            Mögliche Starttermine: ab Wintersemester 2023 `,
        },

        {
          title: "Dein Status (Optional)",
          attr: "myStatus",
          value: "",
          placeholder:
            "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich bin im 3 Semester meines Bachelorstudiums an der FAU Erlangen/Nuernberg",
        },
        {
          title: "Dein Interesse (Optional)",
          attr: "myInterest",
          value: "",
          placeholder:
            "Erzähle kurz darüber, wieso du dich für diese Stelle bewerben möchtest? Worin liegt deine Hauptmotivation? ",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe eine große Begeisterung für Elektronik und Mechanik. ",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung,`,
          //label: "Optional",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: `6 Monate Praktikum bei Tecosim im Bereich Simulation`,
        },
        {
          title: "Deine Qualifikation",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deinen Werdegang... ",
          label: "Beispiel: 2010 - 2016 Bachelor in Informatik",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "2010 - 2016 Bachelor in Informatik",
        },
        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "CAD, SolidWorks, Konstruktion, Programmierung ",
        },
        {
          title: "Sprache",
          attr: "language",
          value: "",
          label: "Examples: Accountant, Developer, Dentist, Receptionist",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Frau Müller, 

        hiermit bewerbe ich mich um die auf indeed.com ausgeschriebene Stelle als Entwicklungsingeniuer Technische Mechanik bei der Bosch GmbH, wie auf indeed.com ausgeschrieben. Ich befinde mich derzeit im dritten Semester meines Bachelorstudiums der Ingenieurwissenschaften an der Hochschule Hof. 
        
        An dieser Stelle bin ich besonders interessiert, da ich hier meine CAD-Kenntnisse, sowie meine Kenntnisse in der Konstruktion und Programmierung einsetzen kann. Ich habe bereits 6 Monate Erfahrung im Bereich der Simulation bei Tecosim gesammelt und bin überzeugt, dass mich diese Erfahrung ausreichend auf die Aufgabe vorbereitet hat.
        
        Neben meinen technischen Fähigkeiten verfüge ich auch über ausgeprägte analytische Fähigkeiten und ausgezeichnete Kommunikations- und Teamfähigkeiten. Ich bin hoch motiviert und proaktiv und habe eine große Leidenschaft für technische Mechanik. Als Beweis dafür habe ich ein Projekt im Bereich der Konstruktion abgeschlossen und arbeite derzeit an einem Projekt im Bereich der Robotik. 
        
        Ich bin überzeugt, dass meine Ausbildung, meine Fähigkeiten und meine bisherigen Erfahrungen mich zu einem idealen Kandidaten für diese Stelle machen. Ich freue mich darauf, zum Team beizutragen und meine Kenntnisse in diesem Bereich weiter auszubauen. 
        
        Vielen Dank, dass Sie meine Bewerbung berücksichtigt haben. 
        
        Mit freundlichen Grüßen, 
        [Ihr Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
