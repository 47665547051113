import { ClipboardListIcon } from "@heroicons/react/solid";
import descriptionText from "./descriptionText";

const obj = {
  title: "Anschreiben für einen Ausbildungsplatz erstellen",
  desc: "Erstellen Sie ein professionelles Anschreiben für eine Ausbildung.",
  category: "Internship",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/ausbildung/ausbildung",
  api: "/ai/ausbildung/ausbildung",
  apiCv: "/ai/upload-cv-motivationletter1",
  currentSchema: "ausbildung",

  fromColor: "yellow-400",
  toColor: "yellow-600",

  output: {
    title: "Dein persönliches Anschreiben.",
    desc: "Beispiel für ein Anschreiben für eine Ausbildung. Hinweis: Wir übernehmen keine Haftung für die Korrektheit, deswegen empfehlen wir den generierten Text ausführlich zu prüfen.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Bewerbungsschreiben Classic",
      desc: descriptionText,
      // n: 1,
      prompts: [
        {
          title: "Titel der Ausbildung",
          attr: "positionTitle",
          value: "",
          placeholder: "Ausbildung als Mechatroniker",
          label:
            "Beispiel: Ausbildung Zahnarzthelfer/in, Ausbildung Verkäufer/in",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Friseur/in",
        },
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: StyleX GmbH",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "StyleX GmbH",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "nameHiringManager",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },
        {
          title: "Quelle der Stellenanzeige (Optional)",
          attr: "sourceListing",
          value: "",
          placeholder: "Wo hast du die Stellenanzeige gefunden?",
          label: "Beispiel: www.arbeitsagentur.de",

          maxLength: 150,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "www.arbeitsagentur.de",
        },
        {
          title: "Stellenbeschreibung",
          attr: "internshipDescription",
          value: "",
          placeholder:
            "Hier kannst du die die komplette Stellenanzeige hineinkopieren. " +
            "Zum Beispiel: Was wir erwarten: Mit einem Schulpraktikum hast du bei uns die Möglichkeit Einblicke in den Beruf...",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `Das erlebst Du als Azubi (m/w/d) bei der StyleX GmbH:
          *  Ein motivierendes und inspirierendes Umfeld in der Beauty Branche mit einem stark ausgeprägten Teamgedanken 
          *  Die Chance, durch zahlreiche Schulungsmöglichkeiten einen individuellen Karriereweg zu beschreiten
          *  Eine vertrauensvolle Zusammenarbeit: Wir schätzen Dich und Deinen Einsatz. Dein eigener Spirit und Deine Individualität sind bei uns herzlich willkommen
          *  Durch unsere großartigen Mitarbeiterrabatte erlebst Du ein „WOW-Erlebnis“, was jedes Friseurherz höher schlagen lässt
          *  Du erhältst eine professionelle Beratung durch unseren Gesundheitspartner 
          *  Eine attraktive Lage der Salons mit guten ÖPNV-Anbindungen
          Das lernst Du bei uns:
          *  Das Waschen, Schneiden, Pflegen und Stylen von Frisuren jeglicher Art
          *  Die richtige Anwendung von Farbtechniken und Pflegeprodukten
          *  Mit welchen Präparaten und Techniken Frisuren gestaltet werden können
          *  Eine typgerechte und fachliche Kundenberatung
          *  Das Organisieren im Salon (bspw. Wareneinkauf, Terminplanung), Pflegen der Kundenkartei und Verkauf von Produkten 
          Das bringst du mit:
          *  Schulnoten sind nicht alles – ein erfolgreich abgeschlossener Schulabschluss ist dennoch wünschenswert
          *  Handwerkliches Geschick, ein gepflegtes Erscheinungsbild sowie Interesse an neuen Trends, Beauty und Styling 
          *  Lernbereitschaft, ein hohes Maß an Motivation und eine freundliche sowie kommunikative Art `,
        },

        {
          title: "Dein Status (Optional)",
          attr: "myStatus",
          value: "",
          placeholder:
            "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe vor kurzem mein Realschulabschluss abgeschlossen und suche nach einer Ausbildung.",
        },
        {
          title: "Dein Interesse (Optional)",
          attr: "myInterest",
          value: "",
          placeholder:
            "Erzähle kurz darüber, wieso du dich für diese Stelle bewerben möchtest? Worin liegt deine Hauptmotivation? ",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe Freude daran mit Menschen zu sprechen und bin Kreativ.",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung,`,
          label: "Optional",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: ` Zweiwöchentliches Schülerpraktikum im Friseursalon
          BeautyMaker GmbH (Musterstadt, DE)
          Kunden empfangen und Telefonate führen 
          Kennenlernen der Geräte und Produkte`,
        },
        {
          title: "Deine Qualifikation",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deinen Werdegang...",
          label: "Beispiel: 2019 - 2022 Mittlere Reife",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: `
          Seit 08/2015 Realschulabschluss an der Musterrealschule in Musterstadt
          Wahlfächer: Physik, Chemie
          Aktuell 9. Klasse
          Angestrebter Abschluss: Mittlere Reife (2022)      
          
          08/2011 – 06/2015	Grundschulabschluss an der Mustergrundschule in Musterstadt
          Abschlussnote (2,0)`,
        },
        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: `Sprachen:	Deutsch – Muttersprache Englisch – Gut (Schulnote 2) 
          IT-Kenntnisse: MS Word – Sehr gut, Anwendung für Schularbeiten
          MS Excel – Grundlagen, Anwendung im Schülerpraktikum`,
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: `Sprachen:	Deutsch – Muttersprache Englisch – Gut (Schulnote 2) 
          IT-Kenntnisse: MS Word – Sehr gut, Anwendung für Schularbeiten
          MS Excel – Grundlagen, Anwendung im Schülerpraktikum`,
        },
        {
          title: "Deine Projekte (Optional)",
          attr: "myProjects",
          value: "",
          placeholder:
            "Erzähle über besondere Projekte, welche du in deiner Freizeit erfolgreich gemeisterst hast.",
          label: "Schüleraustausch in Indien.",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: "Schüleraustausch in Indien.",
        },
        {
          title: "Sprache",
          attr: "language",
          value: "",
          //label: "DE: Deutsch, EN: Englisch",
          // label: "Examples: Accountant, Developer, Dentist, Receptionist",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Frau Müller,

        ich habe kürzlich meinen Realschulabschluss erfolgreich abgeschlossen und bin nun auf der Suche nach einer Ausbildung, die mich sowohl beruflich als auch persönlich weiterbringt. Durch meine Freude am Umgang mit Menschen und meine kreative Ader habe ich großes Interesse an der Position als Friseur bei der StyleX GmbH, auf die ich bei www.arbeitsagentur.de gestoßen bin.
        
        Meine schulische Laufbahn hat mich mit einem breiten Wissen in den Fächern Physik und Chemie ausgestattet, was mir ein grundlegendes Verständnis für die Haarpflege und -behandlungen vermittelt hat. Zudem habe ich zweiwöchentliche Schülerpraktika in einem örtlichen Friseursalon absolviert, bei denen ich die Möglichkeit hatte, Kunden zu empfangen, Telefonate zu führen und die Geräte und Produkte kennenzulernen. Diese Erfahrungen haben meine Leidenschaft für die Friseurbranche weiter gestärkt.
        
        Neben meinen schulischen und praktischen Erfahrungen habe ich auch Sprach- und IT-Kenntnisse, die für diese Position von Vorteil sind. Meine Deutschkenntnisse sind natürlich auf Muttersprachenniveau und ich habe auch gute Englischkenntnisse, die durch eine Schulnote von 2 bestätigt werden. Darüber hinaus habe ich sehr gute Kenntnisse in MS Word, die ich regelmäßig für meine Schularbeiten nutze, und grundlegende Kenntnisse in MS Excel, die ich während meines Schülerpraktikums erworben habe.
        
        Meine Begeisterung für das Friseurhandwerk zeigt sich auch in meinem außerschulischen Engagement. So habe ich beispielsweise an einem Schüleraustausch in Indien teilgenommen, bei dem ich sowohl meine interkulturellen Kompetenzen als auch meine Fähigkeit zur Anpassung unter Beweis stellen konnte.
        
        Ich bin überzeugt, dass ich mit meinen Fähigkeiten, meinem Interesse an Beauty und Styling sowie meiner Lernbereitschaft und Kommunikationsfähigkeit eine wertvolle Ergänzung für das Team von StyleX GmbH sein kann. Ich bin bereit, meine Leidenschaft für Frisuren und meine kreativen Ideen einzubringen, um gemeinsam mit Ihnen herausragende Ergebnisse zu erzielen.
        
        Ich danke Ihnen herzlich dafür, dass Sie meine Bewerbung in Betracht ziehen, und freue mich darauf, mehr über die Ausbildungsmöglichkeiten bei der StyleX GmbH zu erfahren. Mit freundlichen Grüßen,
        
        [Dein Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
    {
      title: "Bewerbungsschreiben Expert",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank!",
      // n: 1,
      prompts: [
        {
          title: "Titel der Ausbildung",
          attr: "positionTitle",
          value: "",
          placeholder: "Ausbildung als Mechatroniker",
          label:
            "Beispiel: Ausbildung Zahnarzthelfer/in, Ausbildung Verkäufer/in",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Friseur/in",
        },
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: StyleX GmbH",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "StyleX GmbH",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "nameHiringManager",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },
        {
          title: "Quelle der Stellenanzeige (Optional)",
          attr: "sourceListing",
          value: "",
          placeholder: "Wo hast du die Stellenanzeige gefunden?",
          label: "Beispiel: www.arbeitsagentur.de",

          maxLength: 150,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "www.arbeitsagentur.de",
        },
        {
          title: "Stellenbeschreibung",
          attr: "internshipDescription",
          value: "",
          placeholder:
            "Hier kannst du die die komplette Stellenanzeige hineinkopieren. " +
            "Zum Beispiel: Was wir erwarten: Mit einem Schulpraktikum hast du bei uns die Möglichkeit Einblicke in den Beruf...",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `Das erlebst Du als Azubi (m/w/d) bei der StyleX GmbH:
          *  Ein motivierendes und inspirierendes Umfeld in der Beauty Branche mit einem stark ausgeprägten Teamgedanken 
          *  Die Chance, durch zahlreiche Schulungsmöglichkeiten einen individuellen Karriereweg zu beschreiten
          *  Eine vertrauensvolle Zusammenarbeit: Wir schätzen Dich und Deinen Einsatz. Dein eigener Spirit und Deine Individualität sind bei uns herzlich willkommen
          *  Durch unsere großartigen Mitarbeiterrabatte erlebst Du ein „WOW-Erlebnis“, was jedes Friseurherz höher schlagen lässt
          *  Du erhältst eine professionelle Beratung durch unseren Gesundheitspartner 
          *  Eine attraktive Lage der Salons mit guten ÖPNV-Anbindungen
          Das lernst Du bei uns:
          *  Das Waschen, Schneiden, Pflegen und Stylen von Frisuren jeglicher Art
          *  Die richtige Anwendung von Farbtechniken und Pflegeprodukten
          *  Mit welchen Präparaten und Techniken Frisuren gestaltet werden können
          *  Eine typgerechte und fachliche Kundenberatung
          *  Das Organisieren im Salon (bspw. Wareneinkauf, Terminplanung), Pflegen der Kundenkartei und Verkauf von Produkten 
          Das bringst du mit:
          *  Schulnoten sind nicht alles – ein erfolgreich abgeschlossener Schulabschluss ist dennoch wünschenswert
          *  Handwerkliches Geschick, ein gepflegtes Erscheinungsbild sowie Interesse an neuen Trends, Beauty und Styling 
          *  Lernbereitschaft, ein hohes Maß an Motivation und eine freundliche sowie kommunikative Art `,
        },

        {
          title: "Dein Status (Optional)",
          attr: "myStatus",
          value: "",
          placeholder:
            "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe vor kurzem mein Realschulabschluss abgeschlossen und suche nach einer Ausbildung.",
        },
        {
          title: "Dein Interesse (Optional)",
          attr: "myInterest",
          value: "",
          placeholder:
            "Erzähle kurz darüber, wieso du dich für diese Stelle bewerben möchtest? Worin liegt deine Hauptmotivation? ",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe Freude daran mit Menschen zu sprechen und bin Kreativ.",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung,`,
          label: "Optional",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: ` Zweiwöchentliches Schülerpraktikum im Friseursalon
          BeautyMaker GmbH (Musterstadt, DE)
          Kunden empfangen und Telefonate führen 
          Kennenlernen der Geräte und Produkte`,
        },
        {
          title: "Deine Qualifikation",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deinen Werdegang...",
          label: "Beispiel: 2019 - 2022 Mittlere Reife",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: `
          Seit 08/2015 Realschulabschluss an der Musterrealschule in Musterstadt
          Wahlfächer: Physik, Chemie
          Aktuell 9. Klasse
          Angestrebter Abschluss: Mittlere Reife (2022)      
          
          08/2011 – 06/2015	Grundschulabschluss an der Mustergrundschule in Musterstadt
          Abschlussnote (2,0)`,
        },
        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: `Sprachen:	Deutsch – Muttersprache Englisch – Gut (Schulnote 2) 
          IT-Kenntnisse: MS Word – Sehr gut, Anwendung für Schularbeiten
          MS Excel – Grundlagen, Anwendung im Schülerpraktikum`,
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: `Sprachen:	Deutsch – Muttersprache Englisch – Gut (Schulnote 2) 
          IT-Kenntnisse: MS Word – Sehr gut, Anwendung für Schularbeiten
          MS Excel – Grundlagen, Anwendung im Schülerpraktikum`,
        },
        {
          title: "Deine Projekte (Optional)",
          attr: "myProjects",
          value: "",
          placeholder:
            "Erzähle über besondere Projekte, welche du in deiner Freizeit erfolgreich gemeisterst hast.",
          label: "Schüleraustausch in Indien.",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: "Schüleraustausch in Indien.",
        },
        {
          title: "Sprache",
          attr: "language",
          value: "",
          //label: "DE: Deutsch, EN: Englisch",
          // label: "Examples: Accountant, Developer, Dentist, Receptionist",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Frau Müller,

        ich habe kürzlich meinen Realschulabschluss erfolgreich abgeschlossen und bin nun auf der Suche nach einer Ausbildung, die mich sowohl beruflich als auch persönlich weiterbringt. Durch meine Freude am Umgang mit Menschen und meine kreative Ader habe ich großes Interesse an der Position als Friseur bei der StyleX GmbH, auf die ich bei www.arbeitsagentur.de gestoßen bin.
        
        Meine schulische Laufbahn hat mich mit einem breiten Wissen in den Fächern Physik und Chemie ausgestattet, was mir ein grundlegendes Verständnis für die Haarpflege und -behandlungen vermittelt hat. Zudem habe ich zweiwöchentliche Schülerpraktika in einem örtlichen Friseursalon absolviert, bei denen ich die Möglichkeit hatte, Kunden zu empfangen, Telefonate zu führen und die Geräte und Produkte kennenzulernen. Diese Erfahrungen haben meine Leidenschaft für die Friseurbranche weiter gestärkt.
        
        Neben meinen schulischen und praktischen Erfahrungen habe ich auch Sprach- und IT-Kenntnisse, die für diese Position von Vorteil sind. Meine Deutschkenntnisse sind natürlich auf Muttersprachenniveau und ich habe auch gute Englischkenntnisse, die durch eine Schulnote von 2 bestätigt werden. Darüber hinaus habe ich sehr gute Kenntnisse in MS Word, die ich regelmäßig für meine Schularbeiten nutze, und grundlegende Kenntnisse in MS Excel, die ich während meines Schülerpraktikums erworben habe.
        
        Meine Begeisterung für das Friseurhandwerk zeigt sich auch in meinem außerschulischen Engagement. So habe ich beispielsweise an einem Schüleraustausch in Indien teilgenommen, bei dem ich sowohl meine interkulturellen Kompetenzen als auch meine Fähigkeit zur Anpassung unter Beweis stellen konnte.
        
        Ich bin überzeugt, dass ich mit meinen Fähigkeiten, meinem Interesse an Beauty und Styling sowie meiner Lernbereitschaft und Kommunikationsfähigkeit eine wertvolle Ergänzung für das Team von StyleX GmbH sein kann. Ich bin bereit, meine Leidenschaft für Frisuren und meine kreativen Ideen einzubringen, um gemeinsam mit Ihnen herausragende Ergebnisse zu erzielen.
        
        Ich danke Ihnen herzlich dafür, dass Sie meine Bewerbung in Betracht ziehen, und freue mich darauf, mehr über die Ausbildungsmöglichkeiten bei der StyleX GmbH zu erfahren. Mit freundlichen Grüßen,
        
        [Dein Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
