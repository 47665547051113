import { ThemeProvider } from "styled-components";
import React, { Component } from "react";

import { Provider } from "mobx-react";
import { observer, inject } from "mobx-react";

// import AppStore from "./store";
import colors from "tailwindcss/colors";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Header from "./Header";
import Search from "./Search";
import Pricing from "./Pricing"; // <--- Add this line

import Dashboard from "./Dashboard";

import Tool from "./Core/Tool_new";
import Chat from "./Core/Chat";

import Login from "./Login/Login";
import EmailVerification from "./Login/EmailVerification";
import ForgotPassword from "./Login/ForgotPassword";
import ResetPassword from "./Login/ResetPassword";

import Profile from "./Profile/";
import LoginSuccess from "./Login/Success";

import HistoryView from "./HistoryView/";

import "./App.scss";

import ReactGA from "react-ga";

/// in production this should be removed
// if (!window.store) {
//   window.store = new AppStore();
// }

ReactGA.initialize("AW-11237872865");

@inject("store")
@observer
class App extends Component {
  render() {
    const { store } = this.props;

    return (
      <ThemeProvider theme={colors}>
        <Provider store={store}>
          <Router>
            {store.redirect ? <Redirect to={store.redirect} /> : null}
            {/* {console.log(store.store)}; */}
            {store.isLoggedIn ? (
              store.profile.status ? (
                <>
                  {/* {store.profile.status ? ( */}
                  {/* <> */} {/*  Logged in with plan */}
                  <Switch>
                    <Route path="/writing/document">
                      <div />
                    </Route>
                    <Route component={Header} />
                  </Switch>
                  <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/search" exact component={Search} />

                    {/* <Route path="/signup/pricing" component={LoginSuccess}>
                    <Pricing />
                  </Route> */}

                    <Route path="/ai/">
                      <Switch>
                        <Route path="/ai/code/debugging" component={Chat} />
                        <Route component={Tool} />
                      </Switch>
                    </Route>
                    <Route path="/signup/pricing" component={Pricing} />
                    <Route path="/my-profile" component={Profile} />
                    <Route path="/signup/failed" component={Profile} />
                    <Route path="/signup/success" component={LoginSuccess} />
                    <Route path="/signup/success" component={LoginSuccess} />
                    <Route path="/history" component={HistoryView} />
                  </Switch>
                </>
              ) : (
                // Logged in but no profile status
                <Switch>
                  <Route path="/signup/success" component={LoginSuccess} />
                  <Route>
                    <Pricing />
                  </Route>
                </Switch>
              )
            ) : (
              // ) : (
              //   <>
              //     {" "}
              //     {/* Logged in but no plan */}
              //     <Switch>
              //       <Route path="/signup/success" component={LoginSuccess} />
              //       <Route>
              //         <Pricing />
              //       </Route>
              //     </Switch>
              //   </>
              // )}{" "}
              // </>
              <>
                {" "}
                {/*  Not Logged In */}
                <Switch>
                  <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPassword}
                  />
                  <Route
                    path="/reset-password"
                    exact
                    component={ResetPassword}
                  />

                  <Route
                    exact
                    path="/verifyUserEmail/:email/:token"
                    component={EmailVerification}
                  />

                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
              </>
            )}
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
