import motivationletter1 from "./motivationletter1";
import motivationletterNew from "./motivationletterNew";
import consulting from "./consulting";
import certificateOfEmployment from "./certificateOfEmployment";
import motivationletter2 from "./motivationletter1";
import motivationletter_initiative from "./motivationletter_initiative";
import internship1 from "./internship1";
import ausbildung from "./ausbildung";
import university1 from "./university1";

const TOOLS = [
  // motivationletter2,
  motivationletter1,

  //consulting,
  certificateOfEmployment,
  motivationletter_initiative,
  internship1,
  ausbildung,
  university1,
  // motivationletterNew,
];

export default TOOLS;
