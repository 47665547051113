import React, { Component } from "react";
import {
  CheckCircleIcon,
  BanIcon,
  DatabaseIcon,
  MenuAlt1Icon,
  LightBulbIcon,
  SparklesIcon,
  UserIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  DocumentTextIcon,
  DownloadIcon,
} from "@heroicons/react/solid";

import Logo from "./Logo";
import config from "./config";

import image from "./media/Images/Unbenannt.JPG";
import image1 from "./media/Images/Unbenannt1.JPG";
import image2 from "./media/Images/Unbenannt2.JPG";
import image3 from "./media/Images/Unbenannt3.JPG";
import image4 from "./media/Images/Unbenannt4.JPG";

import { observer, inject } from "mobx-react";
@inject("store")
@observer
class Pricing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        {this.props.store.profile.status ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 "></div>
              <div
                onClick={this.props.store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> Ausloggen</div>
              </div>
            </div>
          </div>
        )}

        <div className="container mx-auto px-8 py-4 lg:px-28 lg:py-12 lg:pb-64 select-none">
          {this.props.store.profile.status ? null : (
            <>
              <div className="text-center">
                <Logo className="w-32 h-32 inline-block" />
              </div>

              <h2 className="text-xl sm:text-2xl md:text-5xl text-gray-700 text-center">
                Jetzt loslegen
              </h2>
              <p className="text-lg sm:text-xl md:text-2xl text-gray-400 mb-14 mt-2 text-center">
                Wählen Sie eine Preisoption, um AnschreibenAI zu nutzen
              </p>
            </>
          )}

          <Grid>
            {this.props.store.profile.status ? null : (
              <Free
                fromColor="gray-400"
                toColor="gray-500"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
              />
            )}
            {this.props.store.profile.status != null &&
              this.props.store.profile.status !== "" && (
                <Entry
                  fromColor="green-400"
                  toColor="green-600"
                  baseURL={this.props.store.baseURL}
                  api={this.props.store.api}
                />
              )}
            <Premium
              fromColor="indigo-500"
              toColor="red-500"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
            <Ultra
              fromColor="yellow-500"
              toColor="yellow-300"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
          </Grid>

          {/* Hinzugefügter Werbetext */}
          <div className="text-center mt-20 mb-20">
            <span className="text-2xl sm:text-3xl md:text-4xl text-gray-700">
              Über mehr als +200 Zufriedene Kunden
            </span>
          </div>

          {/* Hinzugefügte Logos nach den Preis-Paketen */}

          <div className="flex justify-center mt-12 space-x-6">
            <a
              href="https://de.trustpilot.com/review/anschreibenai.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image1}
                alt="Kundenbewertung 1"
                className="w-41 h-auto transition-transform transform hover:scale-105"
              />
            </a>
            <a
              href="https://de.trustpilot.com/review/anschreibenai.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image2}
                alt="Kundenbewertung 2"
                className="w-41 h-auto transition-transform transform hover:scale-105"
              />
            </a>
          </div>
          <div className="flex justify-center mt-12 space-x-6">
            <a
              href="https://de.trustpilot.com/review/anschreibenai.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image3}
                alt="Kundenbewertung 3"
                className="w-41 h-auto transition-transform transform hover:scale-105"
              />
            </a>
            <a
              href="https://de.trustpilot.com/review/anschreibenai.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image4}
                alt="Kundenbewertung 4"
                className="w-41 h-auto transition-transform transform hover:scale-105"
              />
            </a>
          </div>
          <div className="flex justify-center mt-12 space-x-6">
            <a
              href="https://de.trustpilot.com/review/anschreibenai.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image}
                alt="Kundenbewertung"
                className="w-35 h-auto transition-transform transform hover:scale-105"
              />
            </a>

            {/* <a
              href="https://de.trustpilot.com/review/anschreibenai.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image}
                alt="Kundenbewertung"
                className="w-35 h-auto transition-transform transform hover:scale-105"
              />
            </a> */}
          </div>

          {/* <div className="flex justify-center mt-12 space-x-6">
            <img src={image} alt="Kundenbewertung " className="h-20 w-auto" />
            <img src={image1} alt="Kundenbewertung 1" className="h-20 w-auto" />
            <img src={image2} alt="Kundenbewertung 2" className="h-20 w-auto" />
          </div>

          <div className="flex justify-center mt-12 space-x-6">
            <img src={image3} alt="Kundenbewertung 3" className="h-20 w-auto" />
            <img src={image4} alt="Kundenbewertung 4" className="h-20 w-auto" />
          </div> */}
        </div>
      </>
    );
  }
}

const Free = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Kostenlos Testen
        </div>
        <div className="text-6xl text-black font-bold">0€</div>
        <div className="text-lg text-gray-400 mt-9 "> Kostenlos Testen</div>
        <p className="mt-4 text-lg">
          Der perfekte Einstieg in die Welt automatisierter Anschreiben! Ideal,
          um einen ersten Eindruck von der Qualität und Effizienz unserer
          KI-gesteuerten Anschreiben zu bekommen.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">2</span>
              {` x `}Credit
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DocumentTextIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">1</span>
              {` x `}Anschreiben
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">~250</span>
              {` x `}Wörter
            </div>
          </div>
          {/* <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">~1,200</span>
              {` x `}Buchstaben
            </div>
          </div> */}

          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">Stunden Lebenszeit gespart</span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">
                Eingesparte berechenbare Zeit
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">
                Zugang zu allen Premium Tools
              </span>
            </div>
          </div>

          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400 mb-20">
                Testen der neuen Beta-Funktionen
              </span>
            </div>
          </div>
        </div>
        <div className="py-2 flex  items-center mt-3"></div>
        <div className="py-2 xl:flex hidden  items-center">
          <BanIcon className="w-6 h-6 mr-2 text-white" />
        </div>
        {/* <form
          action={baseURL + "user/freeplan"}
          method="POST"
          className="flex flex-1"
        >
          <input
            type="hidden"
            name="token"
            value={api.defaults.headers.common["x-access-token"]}
          />
          <input type="hidden" name="priceId" value={config.stripe.free} /> */}

        <form
          onSubmit={async (event) => {
            event.preventDefault(); // prevent the form from causing a page reload
            const response = await fetch(baseURL + "user/freeplan", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                token: api.defaults.headers.common["x-access-token"],
                priceId: config.stripe.free,
              }),
            });

            const data = await response.json();

            if (data.status === "success") {
              window.location.href = "/login";
            }
          }}
          className="flex flex-1"
        >
          {/* <input type="hidden" name="trial" value="true" /> */}
          <button
            type="submit"
            style={{ marginBottom: "0px" }} // Added inline style for 20px margin at the bottom
            className={` inset-0 bg-gradient-to-r from-${
              fromColor ? fromColor : "green-400"
            } to-${
              toColor ? toColor : "blue-500"
            } shadow-lg flex-1  rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Jetzt Testen
          </button>
        </form>
      </div>
    </div>
  </div>
);

const Entry = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Starter-Paket
        </div>
        <div className="text-6xl text-black font-bold">
          2,99€<span className="text-lg text-gray-400"> Einmalzahlung</span>
        </div>
        <p className="mt-4 text-lg">
          Der perfekte Einstieg in die Welt automatisierter Anschreiben! Ideal,
          um einen ersten Eindruck von der Qualität und Effizienz unserer
          KI-gesteuerten Anschreiben zu bekommen.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">2</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DocumentTextIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">1</span>
              {` x `}Anschreiben
            </div>
          </div>

          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black"> ca. 250</span>
              {` x `} Wörter
            </div>
          </div>
          {/* <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">60,000</span>
              {` x `}Letters
            </div>
          </div> */}
          <div className="py-2 flex  items-center">
            <UserIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">60</span>
              {` x `}Minuten gespart
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <CheckCircleIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">ca. 1</span>
              {` x `}Stunde Lebenszeit gespart
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <CurrencyDollarIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">20€</span>
              {` x `} eingesparte berechenbare Zeit
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DownloadIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Download als Word Template
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <SparklesIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Zugang zu allen Premium Tools
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <LightBulbIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Testen der neuen Beta-Funktionen
              </span>
            </div>
          </div>
        </div>
        <form
          action={baseURL + "user/stripe/payments"}
          method="POST"
          className="flex flex-1"
        >
          <input
            type="hidden"
            name="token"
            value={api.defaults.headers.common["x-access-token"]}
          />
          <input type="hidden" name="priceId" value={config.stripe.entry} />
          <button
            type="submit"
            className={`mt-8 inset-0 bg-gradient-to-r from-${
              fromColor ? fromColor : "green-400"
            } to-${
              toColor ? toColor : "blue-500"
            } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Jetzt Starten
          </button>
        </form>
      </div>
    </div>
  </div>
);

const Premium = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      }  shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Pro-Paket
        </div>
        <div className="text-6xl text-black font-bold">
          9,99€<span className="text-lg text-gray-400"> Einmalzahlung</span>
        </div>
        <p className="mt-4 text-lg">
          Bringe deine Bewerbungsanschreiben auf das nächste Level! Perfekt für
          Profis, die auf der Suche nach dem gewissen Extra bei ihrer Jobsuche
          sind.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">10</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DocumentTextIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">5</span>
              {` x `}Anschreiben
            </div>
          </div>

          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">ca. 1.250</span>
              {` x `}Wörter
            </div>
          </div>
          {/* <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">240,000</span>
              {` x `}Letters
            </div>
          </div> */}
          <div className="py-2 flex  items-center">
            <UserIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">300</span>
              {` x `}Minuten gespart
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <CheckCircleIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">ca. 5</span>
              {` x `}Stunden Lebenszeit gespart
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <CurrencyDollarIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">100€</span>
              {` x `} eingesparte berechenbare Zeit
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DownloadIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Download als Word Template
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <SparklesIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Zugang zu allen Premium Tools
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <LightBulbIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Testen der neuen Beta-Funktionen
              </span>
            </div>
          </div>
        </div>
        <form
          action={baseURL + "user/stripe/payments"}
          method="POST"
          className="flex flex-1"
        >
          <input
            type="hidden"
            name="token"
            value={api.defaults.headers.common["x-access-token"]}
          />
          <input type="hidden" name="priceId" value={config.stripe.pro} />
          <button
            type="submit"
            className={`mt-8 inset-0 bg-gradient-to-r from-${
              fromColor ? fromColor : "green-400"
            } to-${
              toColor ? toColor : "blue-500"
            } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Jetzt Starten
          </button>
        </form>
      </div>
    </div>
  </div>
);

const Ultra = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      }  shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Ultimate-Paket
        </div>
        <div className="text-6xl text-black font-bold">
          19,99€<span className="text-lg text-gray-400"> Einmalzahlung</span>
        </div>
        <p className="mt-4 text-lg">
          Maximiere deine Chancen und beeindrucke jeden Personalchef! Für alle,
          die absolute Spitzenleistung in ihrem Bewerbungsprozess anstreben.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">30</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DocumentTextIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">15</span>
              {` x `}Anschreiben
            </div>
          </div>

          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">3.750</span>
              {` x `}Wörter
            </div>
          </div>
          {/* <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">240,000</span>
              {` x `}Letters
            </div>
          </div> */}
          <div className="py-2 flex  items-center">
            <UserIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">900</span>
              {` x `}Minuten gespart
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <CheckCircleIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">ca. 15</span>
              {` x `}Stunden Lebenszeit gespart
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <CurrencyDollarIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">300€</span>
              {` x `} eingesparte berechenbare Zeit
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <DownloadIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Download als Word Template
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <SparklesIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Zugang zu allen Premium Tools
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <LightBulbIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Testen der neuen Beta-Funktionen
              </span>
            </div>
          </div>
        </div>
        <form
          action={baseURL + "user/stripe/payments"}
          method="POST"
          className="flex flex-1"
        >
          <input
            type="hidden"
            name="token"
            value={api.defaults.headers.common["x-access-token"]}
          />
          <input type="hidden" name="priceId" value={config.stripe.ultimate} />
          <button
            type="submit"
            className={`mt-8 inset-0 bg-gradient-to-r from-${
              fromColor ? fromColor : "green-400"
            } to-${
              toColor ? toColor : "blue-500"
            } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Jetzt Starten
          </button>
        </form>
      </div>
    </div>
  </div>
);

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-12 mt-4 xl:grid-cols-3 ">{children}</div>
);

export default Pricing;
