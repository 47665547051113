const Logo = (props) => (
  //   <svg
  //     className={props.className || "w-24 h-24"}
  //     viewBox="0 0 148 170"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <rect
  //       x="6.46851"
  //       y="79.6786"
  //       width="100"
  //       height="100"
  //       transform="rotate(-45 6.46851 79.6786)"
  //       fill="url(#paint0_linear)"
  //     />
  //     <circle
  //       cx="48.49"
  //       cy="47.4901"
  //       r="22.9739"
  //       transform="rotate(-45 48.49 47.4901)"
  //       fill="url(#paint1_linear)"
  //     />
  //     <circle
  //       cx="129.135"
  //       cy="116.135"
  //       r="8.58055"
  //       transform="rotate(-45 129.135 116.135)"
  //       fill="url(#paint2_linear)"
  //     />
  //     <rect
  //       x="74"
  //       y="104"
  //       width="48"
  //       height="48"
  //       transform="rotate(-90 74 104)"
  //       fill="url(#paint3_linear)"
  //     />
  //     <rect
  //       y="57.0001"
  //       width="19"
  //       height="19"
  //       transform="rotate(-90 0 57.0001)"
  //       fill="url(#paint4_linear)"
  //     />
  //     <path
  //       d="M18.4354 134.084L63.0843 89.4355L79.4269 150.427L18.4354 134.084Z"
  //       fill="url(#paint5_linear)"
  //     />
  //     <path
  //       d="M101.652 26.6642L123.664 4.65179L131.721 34.7213L101.652 26.6642Z"
  //       fill="#4029B5"
  //     />
  //     <defs>
  //       <linearGradient
  //         id="paint0_linear"
  //         x1="56.4685"
  //         y1="79.6786"
  //         x2="56.4685"
  //         y2="179.679"
  //         gradientUnits="userSpaceOnUse"
  //       >
  //         <stop stopColor="#7B61FF" />
  //         <stop offset="1" stopColor="#2103BA" />
  //       </linearGradient>
  //       <linearGradient
  //         id="paint1_linear"
  //         x1="48.49"
  //         y1="24.5162"
  //         x2="48.49"
  //         y2="70.464"
  //         gradientUnits="userSpaceOnUse"
  //       >
  //         <stop stopColor="#46E1C5" />
  //         <stop offset="1" stopColor="#00836B" />
  //       </linearGradient>
  //       <linearGradient
  //         id="paint2_linear"
  //         x1="129.135"
  //         y1="107.554"
  //         x2="129.135"
  //         y2="124.715"
  //         gradientUnits="userSpaceOnUse"
  //       >
  //         <stop stopColor="#46E1C5" />
  //         <stop offset="1" stopColor="#00836B" />
  //       </linearGradient>
  //       <linearGradient
  //         id="paint3_linear"
  //         x1="98"
  //         y1="104"
  //         x2="98"
  //         y2="152"
  //         gradientUnits="userSpaceOnUse"
  //       >
  //         <stop stopColor="#FFEC40" />
  //         <stop offset="1" stopColor="#FFB931" />
  //       </linearGradient>
  //       <linearGradient
  //         id="paint4_linear"
  //         x1="9.5"
  //         y1="57.0001"
  //         x2="9.5"
  //         y2="76.0001"
  //         gradientUnits="userSpaceOnUse"
  //       >
  //         <stop stopColor="#FFC961" />
  //         <stop offset="1" stopColor="#FFE600" />
  //       </linearGradient>
  //       <linearGradient
  //         id="paint5_linear"
  //         x1="18.4354"
  //         y1="134.084"
  //         x2="74.5"
  //         y2="119"
  //         gradientUnits="userSpaceOnUse"
  //       >
  //         <stop stopColor="#4029B5" />
  //         <stop offset="1" stopColor="#130076" />
  //       </linearGradient>
  //     </defs>
  //   </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 232.59 261.31"
    {...props}
  >
    <defs>
      <linearGradient
        id="c"
        x1={136.61}
        x2={141.35}
        y1={75.64}
        y2={17.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9429" />
        <stop offset={1} stopColor="#f4d63b" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={60.7}
        x2={97.55}
        y1={59.98}
        y2={175.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#ff00c1" />
        <stop offset={0.43} stopColor="#d41bd3" />
        <stop offset={1} stopColor="#9d3deb" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={139.63}
        x2={84.54}
        y1={125.36}
        y2={93.61}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#81578d" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={96.9}
        x2={152.92}
        y1={153.34}
        y2={153.34}
        gradientTransform="rotate(40.53 140.989 94.071)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3b3f42" />
        <stop offset={1} stopColor="#121322" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="e"
        x1={213.3}
        x2={135.73}
        y1={201.58}
        y2={237.26}
      />
      <linearGradient
        xlinkHref="#b"
        id="f"
        x1={81.8}
        x2={83.69}
        y1={227.51}
        y2={267.23}
      />
    </defs>
    <g
      data-name="Ebene 2"
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M148 77.56c-22.91 13.79-45.27 27.57-71.11 27.3 0-.18-4.26-23.61 26.07-73.24C132.69 12.1 166.52-.1 200.73 0A588.64 588.64 0 0 0 148 77.56Z"
        style={{
          fill: "url(#c)",
        }}
      />
      <path
        d="M142.18 92.6a361.42 361.42 0 0 1-38 7.48 62.22 62.22 0 0 0 12-11.11c11.66-14.88 19.28-33.09 29.36-52.67-22.42 7-40.71 17.56-53.87 30.44a109.59 109.59 0 0 0-11.23 12.89c.28-13.29 2.48-27.47 8.55-41.28C64.55 56.31 49 86 43.62 118.44c-.48-26.1 10.44-52.5 17.2-64.69-33.63 36.73-55.6 80.3-42.17 124.93-1.78-5.2-11.1-22.28-18.65-25.82 12.85 18.38 19.61 67.84 19.61 67.84-.26-1.43 3.69-5 3.69-5 8.87-5.89 31.08-10 43.74-7.23-5.77-4.58-16-6.25-16-6.25 22.57-3.62 41.05-18.64 56-37.57-20.15 18.18-47.75 18.12-47.75 18.12C93 178.56 120 144 138.51 114.24 117.09 128.71 92 122.7 92 122.7c26.88-4.22 33.55-8.16 50.18-30.1Z"
        style={{
          fill: "url(#a)",
        }}
      />
      <path
        d="M199.28 1.83C107 44.78 55.57 127.53 30.46 191.39A199.8 199.8 0 0 0 22.92 216l.38-.35c8.87-5.89 31.08-10 43.74-7.23-5.77-4.58-16-6.25-16-6.25 22.57-3.62 41.05-18.64 56-37.57-20.15 18.18-47.75 18.12-47.75 18.12C93 178.56 120 144 138.51 114.24 117.09 128.71 92 122.7 92 122.7c26.91-4.22 33.58-8.16 50.21-30.1a361.76 361.76 0 0 1-37.64 7.4c14.93-5.15 29.08-13.79 43.43-22.44a589.42 589.42 0 0 1 51.28-75.73Z"
        style={{
          mixBlendMode: "multiply",
          fill: "url(#d)",
        }}
      />
      <path
        d="M174.39 16.17c-20.87 15.9-47.34 37.83-68.15 60.76a392.81 392.81 0 0 0-33.68 41.95c-29.36 42.21-49.94 91.8-58.62 141.9-.27 1.08-1.15.18-1-.56 0 0 .07-1.37.21-3.93 1.08-20.73 6.27-44.47 13.58-65.11 15.82-44 42.91-85.12 75.61-118.47 23.42-24 54.36-48 80.7-63.12l-8.58 6.54Z"
        style={{
          fill: "url(#b)",
        }}
      />
      <path
        d="m232.59 224.48-46.69 21.87a159 159 0 0 1-32.71 11.15 157.77 157.77 0 0 1-34.5 3.81H13.45l51.68-23.07a158.67 158.67 0 0 1 64.58-13.76Z"
        style={{
          fill: "url(#e)",
        }}
      />
      <path
        d="M153.19 257.5a157.77 157.77 0 0 1-34.5 3.81H13.45l51.68-23.07a158 158 0 0 1 39.27-11.72Z"
        style={{
          fill: "url(#f)",
        }}
      />
    </g>
  </svg>
);

export default Logo;
